/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Criteria
 */
export interface Criteria {
    /**
     * Primary identifier which is unique within a CDR version.
     * @type {number}
     * @memberof Criteria
     */
    id: number;
    /**
     * The parent id of the criteria. 0 if this is the root node of a criteria tree
     * @type {number}
     * @memberof Criteria
     */
    parentId: number;
    /**
     * type of this criteria
     * @type {string}
     * @memberof Criteria
     */
    type: string;
    /**
     * sub type of this criteria
     * @type {string}
     * @memberof Criteria
     */
    subtype?: string;
    /**
     * concept code
     * @type {string}
     * @memberof Criteria
     */
    code?: string;
    /**
     * concept name
     * @type {string}
     * @memberof Criteria
     */
    name: string;
    /**
     * boolean field which represents if the criteria has children
     * @type {boolean}
     * @memberof Criteria
     */
    group?: boolean;
    /**
     * boolean field which represents if the criteria is selectable
     * @type {boolean}
     * @memberof Criteria
     */
    selectable?: boolean;
    /**
     * count
     * @type {number}
     * @memberof Criteria
     */
    count?: number;
    /**
     * count
     * @type {number}
     * @memberof Criteria
     */
    sourceCount?: number;
    /**
     * domain id
     * @type {string}
     * @memberof Criteria
     */
    domainId?: string;
    /**
     * concept id
     * @type {string}
     * @memberof Criteria
     */
    conceptId?: string;
    /**
     * path of concept in the criteria tree
     * @type {string}
     * @memberof Criteria
     */
    path: string;
    /**
     * synonyms
     * @type {string}
     * @memberof Criteria
     */
    synonyms?: string;
    /**
     * filters clickable concepts
     * @type {number}
     * @memberof Criteria
     */
    canSelect?: number;
}

/**
 * Check if a given object implements the Criteria interface.
 */
export function instanceOfCriteria(value: object): value is Criteria {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('parentId' in value) || value['parentId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('path' in value) || value['path'] === undefined) return false;
    return true;
}

export function CriteriaFromJSON(json: any): Criteria {
    return CriteriaFromJSONTyped(json, false);
}

export function CriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Criteria {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': json['parentId'],
        'type': json['type'],
        'subtype': json['subtype'] == null ? undefined : json['subtype'],
        'code': json['code'] == null ? undefined : json['code'],
        'name': json['name'],
        'group': json['group'] == null ? undefined : json['group'],
        'selectable': json['selectable'] == null ? undefined : json['selectable'],
        'count': json['count'] == null ? undefined : json['count'],
        'sourceCount': json['sourceCount'] == null ? undefined : json['sourceCount'],
        'domainId': json['domainId'] == null ? undefined : json['domainId'],
        'conceptId': json['conceptId'] == null ? undefined : json['conceptId'],
        'path': json['path'],
        'synonyms': json['synonyms'] == null ? undefined : json['synonyms'],
        'canSelect': json['canSelect'] == null ? undefined : json['canSelect'],
    };
}

export function CriteriaToJSON(value?: Criteria | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'parentId': value['parentId'],
        'type': value['type'],
        'subtype': value['subtype'],
        'code': value['code'],
        'name': value['name'],
        'group': value['group'],
        'selectable': value['selectable'],
        'count': value['count'],
        'sourceCount': value['sourceCount'],
        'domainId': value['domainId'],
        'conceptId': value['conceptId'],
        'path': value['path'],
        'synonyms': value['synonyms'],
        'canSelect': value['canSelect'],
    };
}

