/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfigResponse,
} from '../models/index';
import {
    ConfigResponseFromJSON,
    ConfigResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     * Returns some server configuration data.
     */
    async getConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue));
    }

    /**
     * Returns some server configuration data.
     */
    async getConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigResponse> {
        const response = await this.getConfigRaw(initOverrides);
        return await response.value();
    }

}
