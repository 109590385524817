/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyModule
 */
export interface SurveyModule {
    /**
     * the concept ID for the survey module
     * @type {number}
     * @memberof SurveyModule
     */
    conceptId: number;
    /**
     * display name of the module
     * @type {string}
     * @memberof SurveyModule
     */
    name: string;
    /**
     * description of the module
     * @type {string}
     * @memberof SurveyModule
     */
    description: string;
    /**
     * number of questions in the module
     * @type {number}
     * @memberof SurveyModule
     */
    questionCount: number;
    /**
     * number of participants with data in the CDR for questions in this module
     * @type {number}
     * @memberof SurveyModule
     */
    participantCount: number;
    /**
     * survey release order number
     * @type {number}
     * @memberof SurveyModule
     */
    orderNumber?: number;
}

/**
 * Check if a given object implements the SurveyModule interface.
 */
export function instanceOfSurveyModule(value: object): value is SurveyModule {
    if (!('conceptId' in value) || value['conceptId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('questionCount' in value) || value['questionCount'] === undefined) return false;
    if (!('participantCount' in value) || value['participantCount'] === undefined) return false;
    return true;
}

export function SurveyModuleFromJSON(json: any): SurveyModule {
    return SurveyModuleFromJSONTyped(json, false);
}

export function SurveyModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyModule {
    if (json == null) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'],
        'name': json['name'],
        'description': json['description'],
        'questionCount': json['questionCount'],
        'participantCount': json['participantCount'],
        'orderNumber': json['orderNumber'] == null ? undefined : json['orderNumber'],
    };
}

export function SurveyModuleToJSON(value?: SurveyModule | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conceptId': value['conceptId'],
        'name': value['name'],
        'description': value['description'],
        'questionCount': value['questionCount'],
        'participantCount': value['participantCount'],
        'orderNumber': value['orderNumber'],
    };
}

