/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AchillesResult } from './AchillesResult';
import {
    AchillesResultFromJSON,
    AchillesResultFromJSONTyped,
    AchillesResultToJSON,
} from './AchillesResult';

/**
 * 
 * @export
 * @interface AchillesResultListResponse
 */
export interface AchillesResultListResponse {
    /**
     * 
     * @type {Array<AchillesResult>}
     * @memberof AchillesResultListResponse
     */
    items: Array<AchillesResult>;
}

/**
 * Check if a given object implements the AchillesResultListResponse interface.
 */
export function instanceOfAchillesResultListResponse(value: object): value is AchillesResultListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function AchillesResultListResponseFromJSON(json: any): AchillesResultListResponse {
    return AchillesResultListResponseFromJSONTyped(json, false);
}

export function AchillesResultListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AchillesResultListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AchillesResultFromJSON)),
    };
}

export function AchillesResultListResponseToJSON(value?: AchillesResultListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(AchillesResultToJSON)),
    };
}

