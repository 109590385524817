/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SurveyMetadata } from './SurveyMetadata';
import {
    SurveyMetadataFromJSON,
    SurveyMetadataFromJSONTyped,
    SurveyMetadataToJSON,
} from './SurveyMetadata';

/**
 * 
 * @export
 * @interface SurveyMetadataListResponse
 */
export interface SurveyMetadataListResponse {
    /**
     * 
     * @type {Array<SurveyMetadata>}
     * @memberof SurveyMetadataListResponse
     */
    items: Array<SurveyMetadata>;
}

/**
 * Check if a given object implements the SurveyMetadataListResponse interface.
 */
export function instanceOfSurveyMetadataListResponse(value: object): value is SurveyMetadataListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function SurveyMetadataListResponseFromJSON(json: any): SurveyMetadataListResponse {
    return SurveyMetadataListResponseFromJSONTyped(json, false);
}

export function SurveyMetadataListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyMetadataListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SurveyMetadataFromJSON)),
    };
}

export function SurveyMetadataListResponseToJSON(value?: SurveyMetadataListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SurveyMetadataToJSON)),
    };
}

