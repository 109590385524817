/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ErrorCode } from './ErrorCode';
import {
    ErrorCodeFromJSON,
    ErrorCodeFromJSONTyped,
    ErrorCodeToJSON,
} from './ErrorCode';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * Error message
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
    /**
     * Http error status code
     * @type {number}
     * @memberof ErrorResponse
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    errorClassName?: string;
    /**
     * 
     * @type {ErrorCode}
     * @memberof ErrorResponse
     */
    errorCode?: ErrorCode;
    /**
     * Unique ID for this error response, for correlation with backend logs
     * @type {string}
     * @memberof ErrorResponse
     */
    errorUniqueId?: string;
}

/**
 * Check if a given object implements the ErrorResponse interface.
 */
export function instanceOfErrorResponse(value: object): value is ErrorResponse {
    return true;
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
    return ErrorResponseFromJSONTyped(json, false);
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
        'statusCode': json['statusCode'] == null ? undefined : json['statusCode'],
        'errorClassName': json['errorClassName'] == null ? undefined : json['errorClassName'],
        'errorCode': json['errorCode'] == null ? undefined : ErrorCodeFromJSON(json['errorCode']),
        'errorUniqueId': json['errorUniqueId'] == null ? undefined : json['errorUniqueId'],
    };
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'statusCode': value['statusCode'],
        'errorClassName': value['errorClassName'],
        'errorCode': ErrorCodeToJSON(value['errorCode']),
        'errorUniqueId': value['errorUniqueId'],
    };
}

