/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Analysis,
  AnalysisListResponse,
  GenomicFilters,
  SVGenomicFilters,
  SVVariantInfo,
  SVVariantListResponse,
  SVVariantResultSizeRequest,
  SearchSVVariantsRequest,
  SearchVariantsRequest,
  VariantInfo,
  VariantListResponse,
  VariantResultSizeRequest,
} from '../models/index';
import {
    AnalysisFromJSON,
    AnalysisToJSON,
    AnalysisListResponseFromJSON,
    AnalysisListResponseToJSON,
    GenomicFiltersFromJSON,
    GenomicFiltersToJSON,
    SVGenomicFiltersFromJSON,
    SVGenomicFiltersToJSON,
    SVVariantInfoFromJSON,
    SVVariantInfoToJSON,
    SVVariantListResponseFromJSON,
    SVVariantListResponseToJSON,
    SVVariantResultSizeRequestFromJSON,
    SVVariantResultSizeRequestToJSON,
    SearchSVVariantsRequestFromJSON,
    SearchSVVariantsRequestToJSON,
    SearchVariantsRequestFromJSON,
    SearchVariantsRequestToJSON,
    VariantInfoFromJSON,
    VariantInfoToJSON,
    VariantListResponseFromJSON,
    VariantListResponseToJSON,
    VariantResultSizeRequestFromJSON,
    VariantResultSizeRequestToJSON,
} from '../models/index';

export interface GenomicsApiGetGenomicFilterOptionsRequest {
    variantSearchTerm: string;
}

export interface GenomicsApiGetSVGenomicFilterOptionsRequest {
    variantSearchTerm: string;
}

export interface GenomicsApiGetSVVariantDetailsRequest {
    variantId: string;
}

export interface GenomicsApiGetSVVariantSearchResultSizeRequest {
    request?: SVVariantResultSizeRequest;
}

export interface GenomicsApiGetVariantDetailsRequest {
    variantId: string;
}

export interface GenomicsApiGetVariantSearchResultSizeRequest {
    request?: VariantResultSizeRequest;
}

export interface GenomicsApiSearchSVVariantsOperationRequest {
    request?: SearchSVVariantsRequest;
}

export interface GenomicsApiSearchVariantsOperationRequest {
    request?: SearchVariantsRequest;
}

/**
 * 
 */
export class GenomicsApi extends runtime.BaseAPI {

    /**
     * Gets the demographic participant counts
     */
    async getChartDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalysisListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/chart-demo-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisListResponseFromJSON(jsonValue));
    }

    /**
     * Gets the demographic participant counts
     */
    async getChartData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalysisListResponse> {
        const response = await this.getChartDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the size of each genomic filter option
     */
    async getGenomicFilterOptionsRaw(requestParameters: GenomicsApiGetGenomicFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenomicFilters>> {
        if (requestParameters['variantSearchTerm'] == null) {
            throw new runtime.RequiredError(
                'variantSearchTerm',
                'Required parameter "variantSearchTerm" was null or undefined when calling getGenomicFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['variantSearchTerm'] != null) {
            queryParameters['variant-search-term'] = requestParameters['variantSearchTerm'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/genomic-filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenomicFiltersFromJSON(jsonValue));
    }

    /**
     * Gets the size of each genomic filter option
     */
    async getGenomicFilterOptions(variantSearchTerm: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenomicFilters> {
        const response = await this.getGenomicFilterOptionsRaw({ variantSearchTerm: variantSearchTerm }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the participant counts
     */
    async getParticipantCountsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Analysis>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/participant-counts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisFromJSON(jsonValue));
    }

    /**
     * Gets the participant counts
     */
    async getParticipantCounts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Analysis> {
        const response = await this.getParticipantCountsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the size of each genomic filter option
     */
    async getSVGenomicFilterOptionsRaw(requestParameters: GenomicsApiGetSVGenomicFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SVGenomicFilters>> {
        if (requestParameters['variantSearchTerm'] == null) {
            throw new runtime.RequiredError(
                'variantSearchTerm',
                'Required parameter "variantSearchTerm" was null or undefined when calling getSVGenomicFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['variantSearchTerm'] != null) {
            queryParameters['variant-search-term'] = requestParameters['variantSearchTerm'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/sv-genomic-filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SVGenomicFiltersFromJSON(jsonValue));
    }

    /**
     * Gets the size of each genomic filter option
     */
    async getSVGenomicFilterOptions(variantSearchTerm: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SVGenomicFilters> {
        const response = await this.getSVGenomicFilterOptionsRaw({ variantSearchTerm: variantSearchTerm }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the variant details
     */
    async getSVVariantDetailsRaw(requestParameters: GenomicsApiGetSVVariantDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SVVariantInfo>> {
        if (requestParameters['variantId'] == null) {
            throw new runtime.RequiredError(
                'variantId',
                'Required parameter "variantId" was null or undefined when calling getSVVariantDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/sv-variant-details/{variantId}`.replace(`{${"variantId"}}`, encodeURIComponent(String(requestParameters['variantId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SVVariantInfoFromJSON(jsonValue));
    }

    /**
     * Gets the variant details
     */
    async getSVVariantDetails(variantId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SVVariantInfo> {
        const response = await this.getSVVariantDetailsRaw({ variantId: variantId }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the size of variant result list
     */
    async getSVVariantSearchResultSizeRaw(requestParameters: GenomicsApiGetSVVariantSearchResultSizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/sv-variant-search-result-size`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SVVariantResultSizeRequestToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Gets the size of variant result list
     */
    async getSVVariantSearchResultSize(request?: SVVariantResultSizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getSVVariantSearchResultSizeRaw({ request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the variant details
     */
    async getVariantDetailsRaw(requestParameters: GenomicsApiGetVariantDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariantInfo>> {
        if (requestParameters['variantId'] == null) {
            throw new runtime.RequiredError(
                'variantId',
                'Required parameter "variantId" was null or undefined when calling getVariantDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/variant-details/{variantId}`.replace(`{${"variantId"}}`, encodeURIComponent(String(requestParameters['variantId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariantInfoFromJSON(jsonValue));
    }

    /**
     * Gets the variant details
     */
    async getVariantDetails(variantId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariantInfo> {
        const response = await this.getVariantDetailsRaw({ variantId: variantId }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the size of variant result list
     */
    async getVariantSearchResultSizeRaw(requestParameters: GenomicsApiGetVariantSearchResultSizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/variant-search-result-size`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariantResultSizeRequestToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Gets the size of variant result list
     */
    async getVariantSearchResultSize(request?: VariantResultSizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getVariantSearchResultSizeRaw({ request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the list of matched variants
     */
    async searchSVVariantsRaw(requestParameters: GenomicsApiSearchSVVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SVVariantListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/search-sv-variants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchSVVariantsRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SVVariantListResponseFromJSON(jsonValue));
    }

    /**
     * Gets the list of matched variants
     */
    async searchSVVariants(request?: SearchSVVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SVVariantListResponse> {
        const response = await this.searchSVVariantsRaw({ request: request }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the list of matched variants
     */
    async searchVariantsRaw(requestParameters: GenomicsApiSearchVariantsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VariantListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/genomics/search-variants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchVariantsRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VariantListResponseFromJSON(jsonValue));
    }

    /**
     * Gets the list of matched variants
     */
    async searchVariants(request?: SearchVariantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VariantListResponse> {
        const response = await this.searchVariantsRaw({ request: request }, initOverrides);
        return await response.value();
    }

}
