/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SVGenomicFilterOptionList } from './SVGenomicFilterOptionList';
import {
    SVGenomicFilterOptionListFromJSON,
    SVGenomicFilterOptionListFromJSONTyped,
    SVGenomicFilterOptionListToJSON,
} from './SVGenomicFilterOptionList';
import type { SVGenomicFilterOption } from './SVGenomicFilterOption';
import {
    SVGenomicFilterOptionFromJSON,
    SVGenomicFilterOptionFromJSONTyped,
    SVGenomicFilterOptionToJSON,
} from './SVGenomicFilterOption';

/**
 * 
 * @export
 * @interface SVGenomicFilters
 */
export interface SVGenomicFilters {
    /**
     * 
     * @type {SVGenomicFilterOptionList}
     * @memberof SVGenomicFilters
     */
    gene?: SVGenomicFilterOptionList;
    /**
     * 
     * @type {SVGenomicFilterOptionList}
     * @memberof SVGenomicFilters
     */
    consequence?: SVGenomicFilterOptionList;
    /**
     * 
     * @type {SVGenomicFilterOptionList}
     * @memberof SVGenomicFilters
     */
    variantType?: SVGenomicFilterOptionList;
    /**
     * 
     * @type {SVGenomicFilterOption}
     * @memberof SVGenomicFilters
     */
    size?: SVGenomicFilterOption;
    /**
     * 
     * @type {SVGenomicFilterOption}
     * @memberof SVGenomicFilters
     */
    alleleCount?: SVGenomicFilterOption;
    /**
     * 
     * @type {SVGenomicFilterOption}
     * @memberof SVGenomicFilters
     */
    alleleNumber?: SVGenomicFilterOption;
    /**
     * 
     * @type {SVGenomicFilterOption}
     * @memberof SVGenomicFilters
     */
    alleleFrequency?: SVGenomicFilterOption;
    /**
     * 
     * @type {SVGenomicFilterOption}
     * @memberof SVGenomicFilters
     */
    homozygoteCount?: SVGenomicFilterOption;
}

/**
 * Check if a given object implements the SVGenomicFilters interface.
 */
export function instanceOfSVGenomicFilters(value: object): value is SVGenomicFilters {
    return true;
}

export function SVGenomicFiltersFromJSON(json: any): SVGenomicFilters {
    return SVGenomicFiltersFromJSONTyped(json, false);
}

export function SVGenomicFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SVGenomicFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'gene': json['gene'] == null ? undefined : SVGenomicFilterOptionListFromJSON(json['gene']),
        'consequence': json['consequence'] == null ? undefined : SVGenomicFilterOptionListFromJSON(json['consequence']),
        'variantType': json['variantType'] == null ? undefined : SVGenomicFilterOptionListFromJSON(json['variantType']),
        'size': json['size'] == null ? undefined : SVGenomicFilterOptionFromJSON(json['size']),
        'alleleCount': json['alleleCount'] == null ? undefined : SVGenomicFilterOptionFromJSON(json['alleleCount']),
        'alleleNumber': json['alleleNumber'] == null ? undefined : SVGenomicFilterOptionFromJSON(json['alleleNumber']),
        'alleleFrequency': json['alleleFrequency'] == null ? undefined : SVGenomicFilterOptionFromJSON(json['alleleFrequency']),
        'homozygoteCount': json['homozygoteCount'] == null ? undefined : SVGenomicFilterOptionFromJSON(json['homozygoteCount']),
    };
}

export function SVGenomicFiltersToJSON(value?: SVGenomicFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gene': SVGenomicFilterOptionListToJSON(value['gene']),
        'consequence': SVGenomicFilterOptionListToJSON(value['consequence']),
        'variantType': SVGenomicFilterOptionListToJSON(value['variantType']),
        'size': SVGenomicFilterOptionToJSON(value['size']),
        'alleleCount': SVGenomicFilterOptionToJSON(value['alleleCount']),
        'alleleNumber': SVGenomicFilterOptionToJSON(value['alleleNumber']),
        'alleleFrequency': SVGenomicFilterOptionToJSON(value['alleleFrequency']),
        'homozygoteCount': SVGenomicFilterOptionToJSON(value['homozygoteCount']),
    };
}

