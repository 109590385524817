/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Analysis } from './Analysis';
import {
    AnalysisFromJSON,
    AnalysisFromJSONTyped,
    AnalysisToJSON,
} from './Analysis';

/**
 * 
 * @export
 * @interface AnalysisListResponse
 */
export interface AnalysisListResponse {
    /**
     * 
     * @type {Array<Analysis>}
     * @memberof AnalysisListResponse
     */
    items: Array<Analysis>;
}

/**
 * Check if a given object implements the AnalysisListResponse interface.
 */
export function instanceOfAnalysisListResponse(value: object): value is AnalysisListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function AnalysisListResponseFromJSON(json: any): AnalysisListResponse {
    return AnalysisListResponseFromJSONTyped(json, false);
}

export function AnalysisListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AnalysisFromJSON)),
    };
}

export function AnalysisListResponseToJSON(value?: AnalysisListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(AnalysisToJSON)),
    };
}

