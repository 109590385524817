/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SVGenomicFilterOption } from './SVGenomicFilterOption';
import {
    SVGenomicFilterOptionFromJSON,
    SVGenomicFilterOptionFromJSONTyped,
    SVGenomicFilterOptionToJSON,
} from './SVGenomicFilterOption';

/**
 * 
 * @export
 * @interface SVGenomicFilterOptionList
 */
export interface SVGenomicFilterOptionList {
    /**
     * 
     * @type {Array<SVGenomicFilterOption>}
     * @memberof SVGenomicFilterOptionList
     */
    items: Array<SVGenomicFilterOption>;
    /**
     * boolean flag to know whether the filter is active
     * @type {boolean}
     * @memberof SVGenomicFilterOptionList
     */
    filterActive?: boolean;
}

/**
 * Check if a given object implements the SVGenomicFilterOptionList interface.
 */
export function instanceOfSVGenomicFilterOptionList(value: object): value is SVGenomicFilterOptionList {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function SVGenomicFilterOptionListFromJSON(json: any): SVGenomicFilterOptionList {
    return SVGenomicFilterOptionListFromJSONTyped(json, false);
}

export function SVGenomicFilterOptionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SVGenomicFilterOptionList {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SVGenomicFilterOptionFromJSON)),
        'filterActive': json['filterActive'] == null ? undefined : json['filterActive'],
    };
}

export function SVGenomicFilterOptionListToJSON(value?: SVGenomicFilterOptionList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SVGenomicFilterOptionToJSON)),
        'filterActive': value['filterActive'],
    };
}

