/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Analysis } from './Analysis';
import {
    AnalysisFromJSON,
    AnalysisFromJSONTyped,
    AnalysisToJSON,
} from './Analysis';

/**
 * 
 * @export
 * @interface CountAnalysis
 */
export interface CountAnalysis {
    /**
     * domain id
     * @type {string}
     * @memberof CountAnalysis
     */
    domainId: string;
    /**
     * 
     * @type {Analysis}
     * @memberof CountAnalysis
     */
    genderCountAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof CountAnalysis
     */
    ageCountAnalysis?: Analysis;
}

/**
 * Check if a given object implements the CountAnalysis interface.
 */
export function instanceOfCountAnalysis(value: object): value is CountAnalysis {
    if (!('domainId' in value) || value['domainId'] === undefined) return false;
    return true;
}

export function CountAnalysisFromJSON(json: any): CountAnalysis {
    return CountAnalysisFromJSONTyped(json, false);
}

export function CountAnalysisFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountAnalysis {
    if (json == null) {
        return json;
    }
    return {
        
        'domainId': json['domainId'],
        'genderCountAnalysis': json['genderCountAnalysis'] == null ? undefined : AnalysisFromJSON(json['genderCountAnalysis']),
        'ageCountAnalysis': json['ageCountAnalysis'] == null ? undefined : AnalysisFromJSON(json['ageCountAnalysis']),
    };
}

export function CountAnalysisToJSON(value?: CountAnalysis | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'domainId': value['domainId'],
        'genderCountAnalysis': AnalysisToJSON(value['genderCountAnalysis']),
        'ageCountAnalysis': AnalysisToJSON(value['ageCountAnalysis']),
    };
}

