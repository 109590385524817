/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * a domain for concepts corresponding to a table in the OMOP schema
 */
export type Domain = 'OBSERVATION' | 'PROCEDURE' | 'DRUG' | 'CONDITION' | 'MEASUREMENT' | 'DEVICE' | 'DEATH' | 'VISIT';

export const Domain = {
    Observation: 'OBSERVATION' as Domain,
    Procedure: 'PROCEDURE' as Domain,
    Drug: 'DRUG' as Domain,
    Condition: 'CONDITION' as Domain,
    Measurement: 'MEASUREMENT' as Domain,
    Device: 'DEVICE' as Domain,
    Death: 'DEATH' as Domain,
    Visit: 'VISIT' as Domain
};

