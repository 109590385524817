/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CdrVersion } from './CdrVersion';
import {
    CdrVersionFromJSON,
    CdrVersionFromJSONTyped,
    CdrVersionToJSON,
} from './CdrVersion';

/**
 * 
 * @export
 * @interface CdrVersionListResponse
 */
export interface CdrVersionListResponse {
    /**
     * 
     * @type {Array<CdrVersion>}
     * @memberof CdrVersionListResponse
     */
    items: Array<CdrVersion>;
    /**
     * ID of the CDR versions that should be used by the user by default
     * @type {string}
     * @memberof CdrVersionListResponse
     */
    defaultCdrVersionId: string;
}

/**
 * Check if a given object implements the CdrVersionListResponse interface.
 */
export function instanceOfCdrVersionListResponse(value: object): value is CdrVersionListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('defaultCdrVersionId' in value) || value['defaultCdrVersionId'] === undefined) return false;
    return true;
}

export function CdrVersionListResponseFromJSON(json: any): CdrVersionListResponse {
    return CdrVersionListResponseFromJSONTyped(json, false);
}

export function CdrVersionListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdrVersionListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CdrVersionFromJSON)),
        'defaultCdrVersionId': json['defaultCdrVersionId'],
    };
}

export function CdrVersionListResponseToJSON(value?: CdrVersionListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(CdrVersionToJSON)),
        'defaultCdrVersionId': value['defaultCdrVersionId'],
    };
}

