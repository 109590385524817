/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Short parsable error descriptions
 * @export
 */
export const ErrorCode = {
    PARSE_ERROR: 'PARSE_ERROR'
} as const;
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


export function instanceOfErrorCode(value: any): boolean {
    for (const key in ErrorCode) {
        if (Object.prototype.hasOwnProperty.call(ErrorCode, key)) {
            if (ErrorCode[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ErrorCodeFromJSON(json: any): ErrorCode {
    return ErrorCodeFromJSONTyped(json, false);
}

export function ErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCode {
    return json as ErrorCode;
}

export function ErrorCodeToJSON(value?: ErrorCode | null): any {
    return value as any;
}

