/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AchillesResult } from '../model/achillesResult';
// @ts-ignore
import { Analysis } from '../model/analysis';
// @ts-ignore
import { AnalysisListResponse } from '../model/analysisListResponse';
// @ts-ignore
import { CdrVersion } from '../model/cdrVersion';
// @ts-ignore
import { ConceptAnalysisListResponse } from '../model/conceptAnalysisListResponse';
// @ts-ignore
import { ConceptListResponse } from '../model/conceptListResponse';
// @ts-ignore
import { CountAnalysis } from '../model/countAnalysis';
// @ts-ignore
import { CriteriaListResponse } from '../model/criteriaListResponse';
// @ts-ignore
import { CriteriaParentResponse } from '../model/criteriaParentResponse';
// @ts-ignore
import { DomainInfosAndSurveyModulesResponse } from '../model/domainInfosAndSurveyModulesResponse';
// @ts-ignore
import { SearchConceptsRequest } from '../model/searchConceptsRequest';
// @ts-ignore
import { SurveyQuestionFetchResponse } from '../model/surveyQuestionFetchResponse';
// @ts-ignore
import { SurveyVersionCountResponse } from '../model/surveyVersionCountResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DataBrowserService {

    protected basePath = 'https://public-api.pmi-ops.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Gets the cdr versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCdrVersionUsed(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<CdrVersion>;
    public getCdrVersionUsed(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<CdrVersion>>;
    public getCdrVersionUsed(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<CdrVersion>>;
    public getCdrVersionUsed(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/cdrversion-used`;
        return this.httpClient.request<CdrVersion>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets analysis results for concept
     * @param conceptIds concept id
     * @param domainId domain id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConceptAnalysisResults(conceptIds: Array<string>, domainId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ConceptAnalysisListResponse>;
    public getConceptAnalysisResults(conceptIds: Array<string>, domainId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ConceptAnalysisListResponse>>;
    public getConceptAnalysisResults(conceptIds: Array<string>, domainId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ConceptAnalysisListResponse>>;
    public getConceptAnalysisResults(conceptIds: Array<string>, domainId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (conceptIds === null || conceptIds === undefined) {
            throw new Error('Required parameter conceptIds was null or undefined when calling getConceptAnalysisResults.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (conceptIds) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...conceptIds].join(COLLECTION_FORMATS['csv']), 'concept-ids');
        }
        if (domainId !== undefined && domainId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>domainId, 'domain-id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/concept-analysis-results`;
        return this.httpClient.request<ConceptAnalysisListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets EHR biological sex and age count analysis
     * @param domainId domain id
     * @param domainDesc domain desc
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCountAnalysis(domainId: string, domainDesc: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<CountAnalysis>;
    public getCountAnalysis(domainId: string, domainDesc: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<CountAnalysis>>;
    public getCountAnalysis(domainId: string, domainDesc: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<CountAnalysis>>;
    public getCountAnalysis(domainId: string, domainDesc: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (domainId === null || domainId === undefined) {
            throw new Error('Required parameter domainId was null or undefined when calling getCountAnalysis.');
        }
        if (domainDesc === null || domainDesc === undefined) {
            throw new Error('Required parameter domainDesc was null or undefined when calling getCountAnalysis.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (domainId !== undefined && domainId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>domainId, 'domain-id');
        }
        if (domainDesc !== undefined && domainDesc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>domainDesc, 'domain-desc');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/count-analysis`;
        return this.httpClient.request<CountAnalysis>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the children when parent concept is expanded in ui
     * @param parentId parent id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCriteriaChildren(parentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<CriteriaListResponse>;
    public getCriteriaChildren(parentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<CriteriaListResponse>>;
    public getCriteriaChildren(parentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<CriteriaListResponse>>;
    public getCriteriaChildren(parentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling getCriteriaChildren.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'parentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/getCriteriaChildren`;
        return this.httpClient.request<CriteriaListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the rolled up count from criteria
     * @param conceptId parent concept id
     * @param domain parent concept domain
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCriteriaRolledCounts(conceptId: number, domain: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<CriteriaParentResponse>;
    public getCriteriaRolledCounts(conceptId: number, domain: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<CriteriaParentResponse>>;
    public getCriteriaRolledCounts(conceptId: number, domain: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<CriteriaParentResponse>>;
    public getCriteriaRolledCounts(conceptId: number, domain: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling getCriteriaRolledCounts.');
        }
        if (domain === null || domain === undefined) {
            throw new Error('Required parameter domain was null or undefined when calling getCriteriaRolledCounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (conceptId !== undefined && conceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>conceptId, 'conceptId');
        }
        if (domain !== undefined && domain !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>domain, 'domain');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/getCriteriaRolledCounts`;
        return this.httpClient.request<CriteriaParentResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the domain filters and survey modules with the count of all concepts and questions
     * @param searchWord search key word
     * @param testFilter measurement filter
     * @param orderFilter measurement filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainTotals(searchWord?: string, testFilter?: number, orderFilter?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<DomainInfosAndSurveyModulesResponse>;
    public getDomainTotals(searchWord?: string, testFilter?: number, orderFilter?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<DomainInfosAndSurveyModulesResponse>>;
    public getDomainTotals(searchWord?: string, testFilter?: number, orderFilter?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<DomainInfosAndSurveyModulesResponse>>;
    public getDomainTotals(searchWord?: string, testFilter?: number, orderFilter?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchWord !== undefined && searchWord !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchWord, 'searchWord');
        }
        if (testFilter !== undefined && testFilter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>testFilter, 'testFilter');
        }
        if (orderFilter !== undefined && orderFilter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderFilter, 'orderFilter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/domain-totals`;
        return this.httpClient.request<DomainInfosAndSurveyModulesResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets analysis results of fitbit
     * @param conceptNames concept name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFitbitAnalysisResults(conceptNames: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ConceptAnalysisListResponse>;
    public getFitbitAnalysisResults(conceptNames: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ConceptAnalysisListResponse>>;
    public getFitbitAnalysisResults(conceptNames: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ConceptAnalysisListResponse>>;
    public getFitbitAnalysisResults(conceptNames: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (conceptNames === null || conceptNames === undefined) {
            throw new Error('Required parameter conceptNames was null or undefined when calling getFitbitAnalysisResults.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (conceptNames) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...conceptNames].join(COLLECTION_FORMATS['csv']), 'concept-names');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/fitbit-analysis-results`;
        return this.httpClient.request<ConceptAnalysisListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets results for an analysis id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGenderAnalysis(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Analysis>;
    public getGenderAnalysis(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Analysis>>;
    public getGenderAnalysis(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Analysis>>;
    public getGenderAnalysis(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/gender-count`;
        return this.httpClient.request<Analysis>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets results for an analysis id and stratum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParticipantCount(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AchillesResult>;
    public getParticipantCount(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AchillesResult>>;
    public getParticipantCount(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AchillesResult>>;
    public getParticipantCount(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/participant-count`;
        return this.httpClient.request<AchillesResult>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get children of the given concept
     * @param conceptId concept id to get maps to concepts
     * @param minCount minimum source count
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSourceConcepts(conceptId: number, minCount?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ConceptListResponse>;
    public getSourceConcepts(conceptId: number, minCount?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ConceptListResponse>>;
    public getSourceConcepts(conceptId: number, minCount?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ConceptListResponse>>;
    public getSourceConcepts(conceptId: number, minCount?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling getSourceConcepts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (conceptId !== undefined && conceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>conceptId, 'concept_id');
        }
        if (minCount !== undefined && minCount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minCount, 'minCount');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/source-concepts`;
        return this.httpClient.request<ConceptListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get survey sub questions
     * @param surveyConceptId survey concept id
     * @param questionConceptId question concept id
     * @param answerConceptId answer concept id
     * @param level sub question level
     * @param path question path
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubQuestions(surveyConceptId: number, questionConceptId: number, answerConceptId: number, level: number, path?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SurveyQuestionFetchResponse>;
    public getSubQuestions(surveyConceptId: number, questionConceptId: number, answerConceptId: number, level: number, path?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SurveyQuestionFetchResponse>>;
    public getSubQuestions(surveyConceptId: number, questionConceptId: number, answerConceptId: number, level: number, path?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SurveyQuestionFetchResponse>>;
    public getSubQuestions(surveyConceptId: number, questionConceptId: number, answerConceptId: number, level: number, path?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (surveyConceptId === null || surveyConceptId === undefined) {
            throw new Error('Required parameter surveyConceptId was null or undefined when calling getSubQuestions.');
        }
        if (questionConceptId === null || questionConceptId === undefined) {
            throw new Error('Required parameter questionConceptId was null or undefined when calling getSubQuestions.');
        }
        if (answerConceptId === null || answerConceptId === undefined) {
            throw new Error('Required parameter answerConceptId was null or undefined when calling getSubQuestions.');
        }
        if (level === null || level === undefined) {
            throw new Error('Required parameter level was null or undefined when calling getSubQuestions.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (surveyConceptId !== undefined && surveyConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>surveyConceptId, 'survey_concept_id');
        }
        if (questionConceptId !== undefined && questionConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>questionConceptId, 'question_concept_id');
        }
        if (answerConceptId !== undefined && answerConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>answerConceptId, 'answer_concept_id');
        }
        if (level !== undefined && level !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>level, 'level');
        }
        if (path !== undefined && path !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>path, 'path');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/sub-questions`;
        return this.httpClient.request<SurveyQuestionFetchResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets participant count of question by each stratum
     * @param questionConceptId 
     * @param questionPath 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSurveyQuestionCounts(questionConceptId: string, questionPath: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AnalysisListResponse>;
    public getSurveyQuestionCounts(questionConceptId: string, questionPath: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AnalysisListResponse>>;
    public getSurveyQuestionCounts(questionConceptId: string, questionPath: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AnalysisListResponse>>;
    public getSurveyQuestionCounts(questionConceptId: string, questionPath: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (questionConceptId === null || questionConceptId === undefined) {
            throw new Error('Required parameter questionConceptId was null or undefined when calling getSurveyQuestionCounts.');
        }
        if (questionPath === null || questionPath === undefined) {
            throw new Error('Required parameter questionPath was null or undefined when calling getSurveyQuestionCounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (questionConceptId !== undefined && questionConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>questionConceptId, 'questionConceptId');
        }
        if (questionPath !== undefined && questionPath !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>questionPath, 'questionPath');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/survey-question-counts`;
        return this.httpClient.request<AnalysisListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Survey Question Results
     * @param surveyConceptId survey concept id
     * @param questionConceptId question concept id
     * @param questionPath question path
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSurveyQuestionResults(surveyConceptId: number, questionConceptId: number, questionPath?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AnalysisListResponse>;
    public getSurveyQuestionResults(surveyConceptId: number, questionConceptId: number, questionPath?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AnalysisListResponse>>;
    public getSurveyQuestionResults(surveyConceptId: number, questionConceptId: number, questionPath?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AnalysisListResponse>>;
    public getSurveyQuestionResults(surveyConceptId: number, questionConceptId: number, questionPath?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (surveyConceptId === null || surveyConceptId === undefined) {
            throw new Error('Required parameter surveyConceptId was null or undefined when calling getSurveyQuestionResults.');
        }
        if (questionConceptId === null || questionConceptId === undefined) {
            throw new Error('Required parameter questionConceptId was null or undefined when calling getSurveyQuestionResults.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (surveyConceptId !== undefined && surveyConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>surveyConceptId, 'survey_concept_id');
        }
        if (questionConceptId !== undefined && questionConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>questionConceptId, 'question_concept_id');
        }
        if (questionPath !== undefined && questionPath !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>questionPath, 'questionPath');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/survey-question-results`;
        return this.httpClient.request<AnalysisListResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get survey questions
     * @param surveyConceptId survey concept id
     * @param searchWord search word in surveys page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSurveyQuestions(surveyConceptId: number, searchWord?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SurveyQuestionFetchResponse>;
    public getSurveyQuestions(surveyConceptId: number, searchWord?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SurveyQuestionFetchResponse>>;
    public getSurveyQuestions(surveyConceptId: number, searchWord?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SurveyQuestionFetchResponse>>;
    public getSurveyQuestions(surveyConceptId: number, searchWord?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (surveyConceptId === null || surveyConceptId === undefined) {
            throw new Error('Required parameter surveyConceptId was null or undefined when calling getSurveyQuestions.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (surveyConceptId !== undefined && surveyConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>surveyConceptId, 'survey_concept_id');
        }
        if (searchWord !== undefined && searchWord !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchWord, 'search_word');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/survey-questions`;
        return this.httpClient.request<SurveyQuestionFetchResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Survey Version Counts
     * @param surveyConceptId survey concept id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSurveyVersionCounts(surveyConceptId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SurveyVersionCountResponse>;
    public getSurveyVersionCounts(surveyConceptId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SurveyVersionCountResponse>>;
    public getSurveyVersionCounts(surveyConceptId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SurveyVersionCountResponse>>;
    public getSurveyVersionCounts(surveyConceptId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (surveyConceptId === null || surveyConceptId === undefined) {
            throw new Error('Required parameter surveyConceptId was null or undefined when calling getSurveyVersionCounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (surveyConceptId !== undefined && surveyConceptId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>surveyConceptId, 'survey_concept_id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/survey-version-counts`;
        return this.httpClient.request<SurveyVersionCountResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets list of matched concepts
     * @param request search concept request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchConcepts(request?: SearchConceptsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<ConceptListResponse>;
    public searchConcepts(request?: SearchConceptsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<ConceptListResponse>>;
    public searchConcepts(request?: SearchConceptsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<ConceptListResponse>>;
    public searchConcepts(request?: SearchConceptsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (aou_oauth) required
        localVarCredential = this.configuration.lookupCredential('aou_oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/databrowser/searchConcepts`;
        return this.httpClient.request<ConceptListResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: request,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
