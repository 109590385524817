/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeasurementConceptInfo
 */
export interface MeasurementConceptInfo {
    /**
     * 
     * @type {number}
     * @memberof MeasurementConceptInfo
     */
    conceptId?: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementConceptInfo
     */
    hasValues?: number;
}

/**
 * Check if a given object implements the MeasurementConceptInfo interface.
 */
export function instanceOfMeasurementConceptInfo(value: object): value is MeasurementConceptInfo {
    return true;
}

export function MeasurementConceptInfoFromJSON(json: any): MeasurementConceptInfo {
    return MeasurementConceptInfoFromJSONTyped(json, false);
}

export function MeasurementConceptInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementConceptInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'] == null ? undefined : json['conceptId'],
        'hasValues': json['hasValues'] == null ? undefined : json['hasValues'],
    };
}

export function MeasurementConceptInfoToJSON(value?: MeasurementConceptInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conceptId': value['conceptId'],
        'hasValues': value['hasValues'],
    };
}

