/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse {
    /**
     * The cloud project in which this app is running.
     * @type {string}
     * @memberof ConfigResponse
     */
    projectId?: string;
    /**
     * Whether the data is safe to show. An emergency page will show if set to false.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    dataBrowserIsSafe?: boolean;
    /**
     * Stackdriver API key for error reporting, scoped to a particular
     * domain. If unset, Stackdriver error reporting should be disabled.
     * 
     * @type {string}
     * @memberof ConfigResponse
     */
    publicApiKeyForErrorReports?: string;
}

/**
 * Check if a given object implements the ConfigResponse interface.
 */
export function instanceOfConfigResponse(value: object): value is ConfigResponse {
    return true;
}

export function ConfigResponseFromJSON(json: any): ConfigResponse {
    return ConfigResponseFromJSONTyped(json, false);
}

export function ConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'dataBrowserIsSafe': json['dataBrowserIsSafe'] == null ? undefined : json['dataBrowserIsSafe'],
        'publicApiKeyForErrorReports': json['publicApiKeyForErrorReports'] == null ? undefined : json['publicApiKeyForErrorReports'],
    };
}

export function ConfigResponseToJSON(value?: ConfigResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'projectId': value['projectId'],
        'dataBrowserIsSafe': value['dataBrowserIsSafe'],
        'publicApiKeyForErrorReports': value['publicApiKeyForErrorReports'],
    };
}

