/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ErrorCode } from './errorCode';


export interface ErrorResponse { 
    /**
     * Error message
     */
    message?: string;
    /**
     * Http error status code
     */
    statusCode?: number;
    errorClassName?: string;
    errorCode?: ErrorCode;
    /**
     * Unique ID for this error response, for correlation with backend logs
     */
    errorUniqueId?: string;
}
export namespace ErrorResponse {
}


