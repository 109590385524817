/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenomicFilters } from './genomicFilters';


export interface VariantResultSizeRequest { 
    /**
     * A query string that can be used to match a subset of the name (case-insensitively), the entire code value (case-insensitively), or the concept ID. 
     */
    query: string;
    filterMetadata?: GenomicFilters;
}

