/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * filter to toggle lab measurement orders
 * @export
 */
export const OrderFilter = {
    SELECTED: 'SELECTED',
    UNSELECTED: 'UNSELECTED'
} as const;
export type OrderFilter = typeof OrderFilter[keyof typeof OrderFilter];


export function instanceOfOrderFilter(value: any): boolean {
    for (const key in OrderFilter) {
        if (Object.prototype.hasOwnProperty.call(OrderFilter, key)) {
            if (OrderFilter[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrderFilterFromJSON(json: any): OrderFilter {
    return OrderFilterFromJSONTyped(json, false);
}

export function OrderFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderFilter {
    return json as OrderFilter;
}

export function OrderFilterToJSON(value?: OrderFilter | null): any {
    return value as any;
}

