import { reactStyles } from "app/utils";

export const globalStyles = reactStyles({
  bodyDefault: {
    fontFamily: "GothamBook, Arial, sans-serif",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    fontSize: "16px",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#262262",
  },
  primaryDisplay: {
    fontFamily: "GothamBook, Arial, sans-serif",
    fontSize: "36.8px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "45.36px",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#262262",
  },
  secondaryDisplay: {
    fontFamily: "GothamBook,Arial, sans-serif",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "27px",
    fontStretch: "normal",
    lineHeight: "1.47em",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#262262",
  },
  bodyLead: {
    fontFamily: "GothamBook, Arial, sans - serif",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#262262",
  },
  displayBody: {
    fontFamily: "Arial, sans - serif",
    fontSize: "22px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.23,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#262262",
  },
});
