/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AchillesResultDist
 */
export interface AchillesResultDist {
    /**
     * pk id for jpa code to work
     * @type {number}
     * @memberof AchillesResultDist
     */
    id?: number;
    /**
     * id analysis
     * @type {number}
     * @memberof AchillesResultDist
     */
    analysisId: number;
    /**
     * stratum 1
     * @type {string}
     * @memberof AchillesResultDist
     */
    stratum1?: string;
    /**
     * stratum 2
     * @type {string}
     * @memberof AchillesResultDist
     */
    stratum2?: string;
    /**
     * stratum 3
     * @type {string}
     * @memberof AchillesResultDist
     */
    stratum3?: string;
    /**
     * stratum 4
     * @type {string}
     * @memberof AchillesResultDist
     */
    stratum4?: string;
    /**
     * stratum 5
     * @type {string}
     * @memberof AchillesResultDist
     */
    stratum5?: string;
    /**
     * stratum 6
     * @type {string}
     * @memberof AchillesResultDist
     */
    stratum6?: string;
    /**
     * count
     * @type {number}
     * @memberof AchillesResultDist
     */
    countValue?: number;
    /**
     * min value
     * @type {number}
     * @memberof AchillesResultDist
     */
    minValue?: number;
    /**
     * max value
     * @type {number}
     * @memberof AchillesResultDist
     */
    maxValue?: number;
    /**
     * avg value
     * @type {number}
     * @memberof AchillesResultDist
     */
    avgValue?: number;
    /**
     * std deviation value
     * @type {number}
     * @memberof AchillesResultDist
     */
    stdevValue?: number;
    /**
     * median value
     * @type {number}
     * @memberof AchillesResultDist
     */
    medianValue?: number;
    /**
     * 10th percentile  value
     * @type {number}
     * @memberof AchillesResultDist
     */
    p10Value?: number;
    /**
     * 25th percentile value
     * @type {number}
     * @memberof AchillesResultDist
     */
    p25Value?: number;
    /**
     * 75th percentile value
     * @type {number}
     * @memberof AchillesResultDist
     */
    p75Value?: number;
    /**
     * 90th percentile value
     * @type {number}
     * @memberof AchillesResultDist
     */
    p90Value?: number;
}

/**
 * Check if a given object implements the AchillesResultDist interface.
 */
export function instanceOfAchillesResultDist(value: object): value is AchillesResultDist {
    if (!('analysisId' in value) || value['analysisId'] === undefined) return false;
    return true;
}

export function AchillesResultDistFromJSON(json: any): AchillesResultDist {
    return AchillesResultDistFromJSONTyped(json, false);
}

export function AchillesResultDistFromJSONTyped(json: any, ignoreDiscriminator: boolean): AchillesResultDist {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'analysisId': json['analysisId'],
        'stratum1': json['stratum1'] == null ? undefined : json['stratum1'],
        'stratum2': json['stratum2'] == null ? undefined : json['stratum2'],
        'stratum3': json['stratum3'] == null ? undefined : json['stratum3'],
        'stratum4': json['stratum4'] == null ? undefined : json['stratum4'],
        'stratum5': json['stratum5'] == null ? undefined : json['stratum5'],
        'stratum6': json['stratum6'] == null ? undefined : json['stratum6'],
        'countValue': json['countValue'] == null ? undefined : json['countValue'],
        'minValue': json['minValue'] == null ? undefined : json['minValue'],
        'maxValue': json['maxValue'] == null ? undefined : json['maxValue'],
        'avgValue': json['avgValue'] == null ? undefined : json['avgValue'],
        'stdevValue': json['stdevValue'] == null ? undefined : json['stdevValue'],
        'medianValue': json['medianValue'] == null ? undefined : json['medianValue'],
        'p10Value': json['p10Value'] == null ? undefined : json['p10Value'],
        'p25Value': json['p25Value'] == null ? undefined : json['p25Value'],
        'p75Value': json['p75Value'] == null ? undefined : json['p75Value'],
        'p90Value': json['p90Value'] == null ? undefined : json['p90Value'],
    };
}

export function AchillesResultDistToJSON(value?: AchillesResultDist | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'analysisId': value['analysisId'],
        'stratum1': value['stratum1'],
        'stratum2': value['stratum2'],
        'stratum3': value['stratum3'],
        'stratum4': value['stratum4'],
        'stratum5': value['stratum5'],
        'stratum6': value['stratum6'],
        'countValue': value['countValue'],
        'minValue': value['minValue'],
        'maxValue': value['maxValue'],
        'avgValue': value['avgValue'],
        'stdevValue': value['stdevValue'],
        'medianValue': value['medianValue'],
        'p10Value': value['p10Value'],
        'p25Value': value['p25Value'],
        'p75Value': value['p75Value'],
        'p90Value': value['p90Value'],
    };
}

