/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Analysis } from './Analysis';
import {
    AnalysisFromJSON,
    AnalysisFromJSONTyped,
    AnalysisToJSON,
} from './Analysis';

/**
 * 
 * @export
 * @interface SurveyMetadata
 */
export interface SurveyMetadata {
    /**
     * id of each row
     * @type {number}
     * @memberof SurveyMetadata
     */
    id?: number;
    /**
     * id of the concept
     * @type {number}
     * @memberof SurveyMetadata
     */
    conceptId: number;
    /**
     * name of concept
     * @type {string}
     * @memberof SurveyMetadata
     */
    conceptName: string;
    /**
     * survey name
     * @type {string}
     * @memberof SurveyMetadata
     */
    surveyName: string;
    /**
     * original vocab code of concept
     * @type {string}
     * @memberof SurveyMetadata
     */
    conceptCode?: string;
    /**
     * est count in the cdr
     * @type {number}
     * @memberof SurveyMetadata
     */
    countValue?: number;
    /**
     * survey concept id
     * @type {number}
     * @memberof SurveyMetadata
     */
    surveyConceptId?: number;
    /**
     * flag to indicate if a question is a sub question
     * @type {number}
     * @memberof SurveyMetadata
     */
    sub?: number;
    /**
     * question path
     * @type {string}
     * @memberof SurveyMetadata
     */
    path?: string;
    /**
     * flag to indicate if the question is parent question
     * @type {number}
     * @memberof SurveyMetadata
     */
    isParentQuestion?: number;
    /**
     * question order number
     * @type {number}
     * @memberof SurveyMetadata
     */
    orderNumber?: number;
    /**
     * question string
     * @type {string}
     * @memberof SurveyMetadata
     */
    questionString?: string;
    /**
     * type of survey data
     * @type {string}
     * @memberof SurveyMetadata
     */
    type?: string;
    /**
     * 
     * @type {Analysis}
     * @memberof SurveyMetadata
     */
    countAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof SurveyMetadata
     */
    genderAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof SurveyMetadata
     */
    ageAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof SurveyMetadata
     */
    locationAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof SurveyMetadata
     */
    versionAnalysis?: Analysis;
    /**
     * 
     * @type {Analysis}
     * @memberof SurveyMetadata
     */
    participantCountAnalysis?: Analysis;
}

/**
 * Check if a given object implements the SurveyMetadata interface.
 */
export function instanceOfSurveyMetadata(value: object): value is SurveyMetadata {
    if (!('conceptId' in value) || value['conceptId'] === undefined) return false;
    if (!('conceptName' in value) || value['conceptName'] === undefined) return false;
    if (!('surveyName' in value) || value['surveyName'] === undefined) return false;
    return true;
}

export function SurveyMetadataFromJSON(json: any): SurveyMetadata {
    return SurveyMetadataFromJSONTyped(json, false);
}

export function SurveyMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'conceptId': json['conceptId'],
        'conceptName': json['conceptName'],
        'surveyName': json['surveyName'],
        'conceptCode': json['conceptCode'] == null ? undefined : json['conceptCode'],
        'countValue': json['countValue'] == null ? undefined : json['countValue'],
        'surveyConceptId': json['surveyConceptId'] == null ? undefined : json['surveyConceptId'],
        'sub': json['sub'] == null ? undefined : json['sub'],
        'path': json['path'] == null ? undefined : json['path'],
        'isParentQuestion': json['is_parent_question'] == null ? undefined : json['is_parent_question'],
        'orderNumber': json['orderNumber'] == null ? undefined : json['orderNumber'],
        'questionString': json['questionString'] == null ? undefined : json['questionString'],
        'type': json['type'] == null ? undefined : json['type'],
        'countAnalysis': json['countAnalysis'] == null ? undefined : AnalysisFromJSON(json['countAnalysis']),
        'genderAnalysis': json['genderAnalysis'] == null ? undefined : AnalysisFromJSON(json['genderAnalysis']),
        'ageAnalysis': json['ageAnalysis'] == null ? undefined : AnalysisFromJSON(json['ageAnalysis']),
        'locationAnalysis': json['locationAnalysis'] == null ? undefined : AnalysisFromJSON(json['locationAnalysis']),
        'versionAnalysis': json['versionAnalysis'] == null ? undefined : AnalysisFromJSON(json['versionAnalysis']),
        'participantCountAnalysis': json['participantCountAnalysis'] == null ? undefined : AnalysisFromJSON(json['participantCountAnalysis']),
    };
}

export function SurveyMetadataToJSON(value?: SurveyMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'conceptId': value['conceptId'],
        'conceptName': value['conceptName'],
        'surveyName': value['surveyName'],
        'conceptCode': value['conceptCode'],
        'countValue': value['countValue'],
        'surveyConceptId': value['surveyConceptId'],
        'sub': value['sub'],
        'path': value['path'],
        'is_parent_question': value['isParentQuestion'],
        'orderNumber': value['orderNumber'],
        'questionString': value['questionString'],
        'type': value['type'],
        'countAnalysis': AnalysisToJSON(value['countAnalysis']),
        'genderAnalysis': AnalysisToJSON(value['genderAnalysis']),
        'ageAnalysis': AnalysisToJSON(value['ageAnalysis']),
        'locationAnalysis': AnalysisToJSON(value['locationAnalysis']),
        'versionAnalysis': AnalysisToJSON(value['versionAnalysis']),
        'participantCountAnalysis': AnalysisToJSON(value['participantCountAnalysis']),
    };
}

