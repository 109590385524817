/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SVVariant
 */
export interface SVVariant {
    /**
     * id variant
     * @type {string}
     * @memberof SVVariant
     */
    variantId: string;
    /**
     * variant type
     * @type {string}
     * @memberof SVVariant
     */
    variantType?: string;
    /**
     * consequence
     * @type {string}
     * @memberof SVVariant
     */
    consequence?: string;
    /**
     * POS - END
     * @type {string}
     * @memberof SVVariant
     */
    position?: string;
    /**
     * size
     * @type {number}
     * @memberof SVVariant
     */
    size?: number;
    /**
     * allele count
     * @type {number}
     * @memberof SVVariant
     */
    alleleCount?: number;
    /**
     * allele number
     * @type {number}
     * @memberof SVVariant
     */
    alleleNumber?: number;
    /**
     * allele frequency
     * @type {number}
     * @memberof SVVariant
     */
    alleleFrequency?: number;
    /**
     * number of homozygotes
     * @type {number}
     * @memberof SVVariant
     */
    homozygoteCount?: number;
}

/**
 * Check if a given object implements the SVVariant interface.
 */
export function instanceOfSVVariant(value: object): value is SVVariant {
    if (!('variantId' in value) || value['variantId'] === undefined) return false;
    return true;
}

export function SVVariantFromJSON(json: any): SVVariant {
    return SVVariantFromJSONTyped(json, false);
}

export function SVVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): SVVariant {
    if (json == null) {
        return json;
    }
    return {
        
        'variantId': json['variantId'],
        'variantType': json['variantType'] == null ? undefined : json['variantType'],
        'consequence': json['consequence'] == null ? undefined : json['consequence'],
        'position': json['position'] == null ? undefined : json['position'],
        'size': json['size'] == null ? undefined : json['size'],
        'alleleCount': json['alleleCount'] == null ? undefined : json['alleleCount'],
        'alleleNumber': json['alleleNumber'] == null ? undefined : json['alleleNumber'],
        'alleleFrequency': json['alleleFrequency'] == null ? undefined : json['alleleFrequency'],
        'homozygoteCount': json['homozygoteCount'] == null ? undefined : json['homozygoteCount'],
    };
}

export function SVVariantToJSON(value?: SVVariant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variantId': value['variantId'],
        'variantType': value['variantType'],
        'consequence': value['consequence'],
        'position': value['position'],
        'size': value['size'],
        'alleleCount': value['alleleCount'],
        'alleleNumber': value['alleleNumber'],
        'alleleFrequency': value['alleleFrequency'],
        'homozygoteCount': value['homozygoteCount'],
    };
}

