/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GenomicFilterOption } from './GenomicFilterOption';
import {
    GenomicFilterOptionFromJSON,
    GenomicFilterOptionFromJSONTyped,
    GenomicFilterOptionToJSON,
} from './GenomicFilterOption';

/**
 * 
 * @export
 * @interface GenomicFilterOptionList
 */
export interface GenomicFilterOptionList {
    /**
     * 
     * @type {Array<GenomicFilterOption>}
     * @memberof GenomicFilterOptionList
     */
    items: Array<GenomicFilterOption>;
    /**
     * boolean flag to know whether the filter is active
     * @type {boolean}
     * @memberof GenomicFilterOptionList
     */
    filterActive?: boolean;
}

/**
 * Check if a given object implements the GenomicFilterOptionList interface.
 */
export function instanceOfGenomicFilterOptionList(value: object): value is GenomicFilterOptionList {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function GenomicFilterOptionListFromJSON(json: any): GenomicFilterOptionList {
    return GenomicFilterOptionListFromJSONTyped(json, false);
}

export function GenomicFilterOptionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenomicFilterOptionList {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(GenomicFilterOptionFromJSON)),
        'filterActive': json['filterActive'] == null ? undefined : json['filterActive'],
    };
}

export function GenomicFilterOptionListToJSON(value?: GenomicFilterOptionList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(GenomicFilterOptionToJSON)),
        'filterActive': value['filterActive'],
    };
}

