/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * filter on whether standard, non-standard, or all concepts should be returned\
 * @export
 */
export const StandardConceptFilter = {
    ALL_CONCEPTS: 'ALL_CONCEPTS',
    STANDARD_CONCEPTS: 'STANDARD_CONCEPTS',
    NON_STANDARD_CONCEPTS: 'NON_STANDARD_CONCEPTS',
    STANDARD_OR_CODE_ID_MATCH: 'STANDARD_OR_CODE_ID_MATCH'
} as const;
export type StandardConceptFilter = typeof StandardConceptFilter[keyof typeof StandardConceptFilter];


export function instanceOfStandardConceptFilter(value: any): boolean {
    for (const key in StandardConceptFilter) {
        if (Object.prototype.hasOwnProperty.call(StandardConceptFilter, key)) {
            if (StandardConceptFilter[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StandardConceptFilterFromJSON(json: any): StandardConceptFilter {
    return StandardConceptFilterFromJSONTyped(json, false);
}

export function StandardConceptFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandardConceptFilter {
    return json as StandardConceptFilter;
}

export function StandardConceptFilterToJSON(value?: StandardConceptFilter | null): any {
    return value as any;
}

