/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AchillesResultDist { 
    /**
     * pk id for jpa code to work
     */
    id?: number;
    /**
     * id analysis
     */
    analysisId: number;
    /**
     * stratum 1
     */
    stratum1?: string;
    /**
     * stratum 2
     */
    stratum2?: string;
    /**
     * stratum 3
     */
    stratum3?: string;
    /**
     * stratum 4
     */
    stratum4?: string;
    /**
     * stratum 5
     */
    stratum5?: string;
    /**
     * stratum 6
     */
    stratum6?: string;
    /**
     * count
     */
    countValue?: number;
    /**
     * min value
     */
    minValue?: number;
    /**
     * max value
     */
    maxValue?: number;
    /**
     * avg value
     */
    avgValue?: number;
    /**
     * std deviation value
     */
    stdevValue?: number;
    /**
     * median value
     */
    medianValue?: number;
    /**
     * 10th percentile  value
     */
    p10Value?: number;
    /**
     * 25th percentile value
     */
    p25Value?: number;
    /**
     * 75th percentile value
     */
    p75Value?: number;
    /**
     * 90th percentile value
     */
    p90Value?: number;
}

