/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Analysis } from './analysis';


export interface SurveyMetadata { 
    /**
     * id of each row
     */
    id?: number;
    /**
     * id of the concept
     */
    conceptId: number;
    /**
     * name of concept
     */
    conceptName: string;
    /**
     * survey name
     */
    surveyName: string;
    /**
     * original vocab code of concept
     */
    conceptCode?: string;
    /**
     * est count in the cdr
     */
    countValue?: number;
    /**
     * survey concept id
     */
    surveyConceptId?: number;
    /**
     * flag to indicate if a question is a sub question
     */
    sub?: number;
    /**
     * question path
     */
    path?: string;
    /**
     * flag to indicate if the question is parent question
     */
    is_parent_question?: number;
    /**
     * question order number
     */
    orderNumber?: number;
    /**
     * question string
     */
    questionString?: string;
    /**
     * type of survey data
     */
    type?: string;
    countAnalysis?: Analysis;
    genderAnalysis?: Analysis;
    ageAnalysis?: Analysis;
    locationAnalysis?: Analysis;
    versionAnalysis?: Analysis;
    participantCountAnalysis?: Analysis;
}

