/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Concept } from './Concept';
import {
    ConceptFromJSON,
    ConceptFromJSONTyped,
    ConceptToJSON,
} from './Concept';
import type { MatchType } from './MatchType';
import {
    MatchTypeFromJSON,
    MatchTypeFromJSONTyped,
    MatchTypeToJSON,
} from './MatchType';

/**
 * 
 * @export
 * @interface ConceptListResponse
 */
export interface ConceptListResponse {
    /**
     * 
     * @type {Array<Concept>}
     * @memberof ConceptListResponse
     */
    items: Array<Concept>;
    /**
     * 
     * @type {MatchType}
     * @memberof ConceptListResponse
     */
    matchType?: MatchType;
    /**
     * matched concept name
     * @type {string}
     * @memberof ConceptListResponse
     */
    matchedConceptName?: string;
}

/**
 * Check if a given object implements the ConceptListResponse interface.
 */
export function instanceOfConceptListResponse(value: object): value is ConceptListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function ConceptListResponseFromJSON(json: any): ConceptListResponse {
    return ConceptListResponseFromJSONTyped(json, false);
}

export function ConceptListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ConceptFromJSON)),
        'matchType': json['matchType'] == null ? undefined : MatchTypeFromJSON(json['matchType']),
        'matchedConceptName': json['matchedConceptName'] == null ? undefined : json['matchedConceptName'],
    };
}

export function ConceptListResponseToJSON(value?: ConceptListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(ConceptToJSON)),
        'matchType': MatchTypeToJSON(value['matchType']),
        'matchedConceptName': value['matchedConceptName'],
    };
}

