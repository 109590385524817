/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * analysis id constant map
 * @export
 */
export const AnalysisIdConstant = {
    PARTICIPANT_COUNT_ANALYSIS_ID: 'PARTICIPANT_COUNT_ANALYSIS_ID',
    GENDER_ANALYSIS: 'GENDER_ANALYSIS',
    COUNT_ANALYSIS_ID: 'COUNT_ANALYSIS_ID',
    SURVEY_GENDER_COUNT_ANALYSIS_ID: 'SURVEY_GENDER_COUNT_ANALYSIS_ID',
    SURVEY_AGE_COUNT_ANALYSIS_ID: 'SURVEY_AGE_COUNT_ANALYSIS_ID',
    SURVEY_PARTICIPANT_COUNT_ANALYSIS_ID: 'SURVEY_PARTICIPANT_COUNT_ANALYSIS_ID',
    GENDER_ANALYSIS_ID: 'GENDER_ANALYSIS_ID',
    PARTICIPANT_COUNT_BY_DATE_ANALYSIS_ID: 'PARTICIPANT_COUNT_BY_DATE_ANALYSIS_ID',
    AGE_ANALYSIS_ID: 'AGE_ANALYSIS_ID',
    SURVEY_VERSION_PARTICIPANT_COUNT_ANALYSIS_ID: 'SURVEY_VERSION_PARTICIPANT_COUNT_ANALYSIS_ID',
    SURVEY_VERSION_QUESTION_COUNT_ANALYSIS_ID: 'SURVEY_VERSION_QUESTION_COUNT_ANALYSIS_ID',
    SURVEY_COUNT_ANALYSIS_ID: 'SURVEY_COUNT_ANALYSIS_ID',
    SURVEY_GENDER_ANALYSIS_ID: 'SURVEY_GENDER_ANALYSIS_ID',
    SURVEY_AGE_ANALYSIS_ID: 'SURVEY_AGE_ANALYSIS_ID',
    SURVEY_VERSION_ANALYSIS_ID: 'SURVEY_VERSION_ANALYSIS_ID',
    SURVEY_LOCATION_ANALYSIS_ID: 'SURVEY_LOCATION_ANALYSIS_ID',
    SURVEY_QUESTION_GENDER_COUNT_ANALYSIS_ID: 'SURVEY_QUESTION_GENDER_COUNT_ANALYSIS_ID',
    SURVEY_QUESTION_AGE_COUNT_ANALYSIS_ID: 'SURVEY_QUESTION_AGE_COUNT_ANALYSIS_ID',
    EHR_GENDER_COUNT_ANALYSIS_ID: 'EHR_GENDER_COUNT_ANALYSIS_ID',
    EHR_AGE_COUNT_ANALYSIS_ID: 'EHR_AGE_COUNT_ANALYSIS_ID',
    RACE_ANALYSIS_ID: 'RACE_ANALYSIS_ID',
    ETHNICITY_ANALYSIS_ID: 'ETHNICITY_ANALYSIS_ID',
    MEASUREMENT_DIST_ANALYSIS_ID: 'MEASUREMENT_DIST_ANALYSIS_ID',
    MEASUREMENT_GENDER_ANALYSIS_ID: 'MEASUREMENT_GENDER_ANALYSIS_ID',
    MEASUREMENT_GENDER_UNIT_ANALYSIS_ID: 'MEASUREMENT_GENDER_UNIT_ANALYSIS_ID',
    RACE_ANALYSIS: 'RACE_ANALYSIS',
    ETHNICITY_ANALYSIS: 'ETHNICITY_ANALYSIS',
    GENO_GENDER_ANALYSIS: 'GENO_GENDER_ANALYSIS',
    GENO_AGE_ANALYSIS: 'GENO_AGE_ANALYSIS',
    GENO_RACE_ANALYSIS: 'GENO_RACE_ANALYSIS',
    GENO_LOCATION_ANALYSIS: 'GENO_LOCATION_ANALYSIS',
    LOCATION_ANALYSIS_ID: 'LOCATION_ANALYSIS_ID'
} as const;
export type AnalysisIdConstant = typeof AnalysisIdConstant[keyof typeof AnalysisIdConstant];


export function instanceOfAnalysisIdConstant(value: any): boolean {
    for (const key in AnalysisIdConstant) {
        if (Object.prototype.hasOwnProperty.call(AnalysisIdConstant, key)) {
            if (AnalysisIdConstant[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AnalysisIdConstantFromJSON(json: any): AnalysisIdConstant {
    return AnalysisIdConstantFromJSONTyped(json, false);
}

export function AnalysisIdConstantFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisIdConstant {
    return json as AnalysisIdConstant;
}

export function AnalysisIdConstantToJSON(value?: AnalysisIdConstant | null): any {
    return value as any;
}

