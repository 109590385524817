/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CdrVersion } from './cdrVersion';


export interface CdrVersionListResponse { 
    items: Array<CdrVersion>;
    /**
     * ID of the CDR versions that should be used by the user by default
     */
    defaultCdrVersionId: string;
}

