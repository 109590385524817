/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SurveyModule } from './SurveyModule';
import {
    SurveyModuleFromJSON,
    SurveyModuleFromJSONTyped,
    SurveyModuleToJSON,
} from './SurveyModule';
import type { DomainInfo } from './DomainInfo';
import {
    DomainInfoFromJSON,
    DomainInfoFromJSONTyped,
    DomainInfoToJSON,
} from './DomainInfo';

/**
 * 
 * @export
 * @interface DomainInfosAndSurveyModulesResponse
 */
export interface DomainInfosAndSurveyModulesResponse {
    /**
     * 
     * @type {Array<DomainInfo>}
     * @memberof DomainInfosAndSurveyModulesResponse
     */
    domainInfos: Array<DomainInfo>;
    /**
     * 
     * @type {Array<SurveyModule>}
     * @memberof DomainInfosAndSurveyModulesResponse
     */
    surveyModules: Array<SurveyModule>;
}

/**
 * Check if a given object implements the DomainInfosAndSurveyModulesResponse interface.
 */
export function instanceOfDomainInfosAndSurveyModulesResponse(value: object): value is DomainInfosAndSurveyModulesResponse {
    if (!('domainInfos' in value) || value['domainInfos'] === undefined) return false;
    if (!('surveyModules' in value) || value['surveyModules'] === undefined) return false;
    return true;
}

export function DomainInfosAndSurveyModulesResponseFromJSON(json: any): DomainInfosAndSurveyModulesResponse {
    return DomainInfosAndSurveyModulesResponseFromJSONTyped(json, false);
}

export function DomainInfosAndSurveyModulesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainInfosAndSurveyModulesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'domainInfos': ((json['domainInfos'] as Array<any>).map(DomainInfoFromJSON)),
        'surveyModules': ((json['surveyModules'] as Array<any>).map(SurveyModuleFromJSON)),
    };
}

export function DomainInfosAndSurveyModulesResponseToJSON(value?: DomainInfosAndSurveyModulesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'domainInfos': ((value['domainInfos'] as Array<any>).map(DomainInfoToJSON)),
        'surveyModules': ((value['surveyModules'] as Array<any>).map(SurveyModuleToJSON)),
    };
}

