/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AchillesResult { 
    /**
     * pk id for jpa code to work
     */
    id?: number;
    /**
     * id analysis
     */
    analysisId: number;
    /**
     * stratum 1
     */
    stratum1?: string;
    /**
     * stratum 2
     */
    stratum2?: string;
    /**
     * stratum 3
     */
    stratum3?: string;
    /**
     * stratum 4
     */
    stratum4?: string;
    /**
     * stratum 5
     */
    stratum5?: string;
    /**
     * stratum6
     */
    stratum6?: string;
    /**
     * stratum7
     */
    stratum7?: string;
    /**
     * stratum 5 Name
     */
    analysisStratumName?: string;
    /**
     * measurement value type (text / numeric)
     */
    measurementValueType?: string;
    /**
     * count
     */
    countValue?: number;
    /**
     * source count
     */
    sourceCountValue?: number;
}

