/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SVVariant { 
    /**
     * id variant
     */
    variantId: string;
    /**
     * variant type
     */
    variantType?: string;
    /**
     * consequence
     */
    consequence?: string;
    /**
     * POS - END
     */
    position?: string;
    /**
     * size
     */
    size?: number;
    /**
     * allele count
     */
    alleleCount?: number;
    /**
     * allele number
     */
    alleleNumber?: number;
    /**
     * allele frequency
     */
    alleleFrequency?: number;
    /**
     * number of homozygotes
     */
    homozygoteCount?: number;
}

