/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Domain } from './domain';


export interface DomainInfo { 
    domain: Domain;
    /**
     * domain concept id
     */
    domainConceptId?: number;
    /**
     * display name of the domain
     */
    name: string;
    /**
     * description of the domain
     */
    description: string;
    /**
     * number of standard concepts in this domain
     */
    standardConceptCount: number;
    /**
     * number of concepts in this domain (standard or non-standard)
     */
    allConceptCount: number;
    /**
     * number of participants with data in the CDR for a concept in this domain
     */
    participantCount: number;
}
export namespace DomainInfo {
}


