/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SurveyModule { 
    /**
     * the concept ID for the survey module
     */
    conceptId: number;
    /**
     * display name of the module
     */
    name: string;
    /**
     * description of the module
     */
    description: string;
    /**
     * number of questions in the module
     */
    questionCount: number;
    /**
     * number of participants with data in the CDR for questions in this module
     */
    participantCount: number;
    /**
     * survey release order number
     */
    orderNumber?: number;
}

