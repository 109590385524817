/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Criteria { 
    /**
     * Primary identifier which is unique within a CDR version.
     */
    id: number;
    /**
     * The parent id of the criteria. 0 if this is the root node of a criteria tree
     */
    parentId: number;
    /**
     * type of this criteria
     */
    type: string;
    /**
     * sub type of this criteria
     */
    subtype?: string;
    /**
     * concept code
     */
    code?: string;
    /**
     * concept name
     */
    name: string;
    /**
     * boolean field which represents if the criteria has children
     */
    group?: boolean;
    /**
     * boolean field which represents if the criteria is selectable
     */
    selectable?: boolean;
    /**
     * count
     */
    count?: number;
    /**
     * count
     */
    sourceCount?: number;
    /**
     * domain id
     */
    domainId?: string;
    /**
     * concept id
     */
    conceptId?: string;
    /**
     * path of concept in the criteria tree
     */
    path: string;
    /**
     * synonyms
     */
    synonyms?: string;
    /**
     * filters clickable concepts
     */
    canSelect?: number;
}

