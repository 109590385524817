/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StandardConceptFilter } from './standardConceptFilter';
import { Domain } from './domain';


export interface SearchConceptsRequest { 
    /**
     * A query string that can be used to match a subset of the name (case-insensitively), the entire code value (case-insensitively), or the concept ID. 
     */
    query: string;
    standardConceptFilter?: StandardConceptFilter;
    /**
     * The vocabulary ID for the concepts returned (e.g. SNOMED, RxNorm)
     */
    vocabularyIds?: Array<string>;
    domain?: Domain;
    /**
     * The maximum number of results returned. Defaults to 25.
     */
    maxResults?: number;
    /**
     * The minimum count of concepts to be fetched
     */
    minCount?: number;
    /**
     * By default it returns the first page and then its next pages from that on.
     */
    pageNumber?: number;
    /**
     * By default all the measurement tests are returned
     */
    measurementTests?: number;
    /**
     * By default all the measurement orders are returned
     */
    measurementOrders?: number;
}
export namespace SearchConceptsRequest {
}


