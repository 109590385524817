/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenomicFilterOption
 */
export interface GenomicFilterOption {
    /**
     * Filter Name
     * @type {string}
     * @memberof GenomicFilterOption
     */
    option?: string;
    /**
     * count
     * @type {number}
     * @memberof GenomicFilterOption
     */
    count?: number;
    /**
     * min value
     * @type {number}
     * @memberof GenomicFilterOption
     */
    min?: number;
    /**
     * max value
     * @type {number}
     * @memberof GenomicFilterOption
     */
    max?: number;
    /**
     * min Allele Frequency
     * @type {number}
     * @memberof GenomicFilterOption
     */
    minFreq?: number;
    /**
     * max Allele Frequency
     * @type {number}
     * @memberof GenomicFilterOption
     */
    maxFreq?: number;
    /**
     * boolean flag
     * @type {boolean}
     * @memberof GenomicFilterOption
     */
    checked?: boolean;
}

/**
 * Check if a given object implements the GenomicFilterOption interface.
 */
export function instanceOfGenomicFilterOption(value: object): value is GenomicFilterOption {
    return true;
}

export function GenomicFilterOptionFromJSON(json: any): GenomicFilterOption {
    return GenomicFilterOptionFromJSONTyped(json, false);
}

export function GenomicFilterOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenomicFilterOption {
    if (json == null) {
        return json;
    }
    return {
        
        'option': json['option'] == null ? undefined : json['option'],
        'count': json['count'] == null ? undefined : json['count'],
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
        'minFreq': json['minFreq'] == null ? undefined : json['minFreq'],
        'maxFreq': json['maxFreq'] == null ? undefined : json['maxFreq'],
        'checked': json['checked'] == null ? undefined : json['checked'],
    };
}

export function GenomicFilterOptionToJSON(value?: GenomicFilterOption | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'option': value['option'],
        'count': value['count'],
        'min': value['min'],
        'max': value['max'],
        'minFreq': value['minFreq'],
        'maxFreq': value['maxFreq'],
        'checked': value['checked'],
    };
}

