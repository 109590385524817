/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SurveyMetadataListResponse } from './SurveyMetadataListResponse';
import {
    SurveyMetadataListResponseFromJSON,
    SurveyMetadataListResponseFromJSONTyped,
    SurveyMetadataListResponseToJSON,
} from './SurveyMetadataListResponse';

/**
 * 
 * @export
 * @interface SubQuestionFetchResponse
 */
export interface SubQuestionFetchResponse {
    /**
     * 
     * @type {SurveyMetadataListResponse}
     * @memberof SubQuestionFetchResponse
     */
    questions?: SurveyMetadataListResponse;
}

/**
 * Check if a given object implements the SubQuestionFetchResponse interface.
 */
export function instanceOfSubQuestionFetchResponse(value: object): value is SubQuestionFetchResponse {
    return true;
}

export function SubQuestionFetchResponseFromJSON(json: any): SubQuestionFetchResponse {
    return SubQuestionFetchResponseFromJSONTyped(json, false);
}

export function SubQuestionFetchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubQuestionFetchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'questions': json['questions'] == null ? undefined : SurveyMetadataListResponseFromJSON(json['questions']),
    };
}

export function SubQuestionFetchResponseToJSON(value?: SubQuestionFetchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'questions': SurveyMetadataListResponseToJSON(value['questions']),
    };
}

