/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConceptAnalysis } from './ConceptAnalysis';
import {
    ConceptAnalysisFromJSON,
    ConceptAnalysisFromJSONTyped,
    ConceptAnalysisToJSON,
} from './ConceptAnalysis';

/**
 * 
 * @export
 * @interface ConceptAnalysisListResponse
 */
export interface ConceptAnalysisListResponse {
    /**
     * 
     * @type {Array<ConceptAnalysis>}
     * @memberof ConceptAnalysisListResponse
     */
    items: Array<ConceptAnalysis>;
}

/**
 * Check if a given object implements the ConceptAnalysisListResponse interface.
 */
export function instanceOfConceptAnalysisListResponse(value: object): value is ConceptAnalysisListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function ConceptAnalysisListResponseFromJSON(json: any): ConceptAnalysisListResponse {
    return ConceptAnalysisListResponseFromJSONTyped(json, false);
}

export function ConceptAnalysisListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptAnalysisListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ConceptAnalysisFromJSON)),
    };
}

export function ConceptAnalysisListResponseToJSON(value?: ConceptAnalysisListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(ConceptAnalysisToJSON)),
    };
}

