/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * match column type on concept search
 */
export type MatchType = 'CONCEPT_CODE' | 'CONCEPT_ID' | 'CONCEPT_NAME';

export const MatchType = {
    Code: 'CONCEPT_CODE' as MatchType,
    Id: 'CONCEPT_ID' as MatchType,
    Name: 'CONCEPT_NAME' as MatchType
};

