/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MatchType } from './matchType';
import { MeasurementConceptInfo } from './measurementConceptInfo';


export interface Concept { 
    /**
     * id of the concept
     */
    conceptId: number;
    /**
     * name of concept
     */
    conceptName: string;
    /**
     * domain of concept
     */
    domainId: string;
    /**
     * vocabulary of concept
     */
    vocabularyId: string;
    /**
     * original vocab code of concept
     */
    conceptCode: string;
    /**
     * class of concept
     */
    conceptClassId: string;
    /**
     * standard concept value 1 char
     */
    standardConcept: string;
    /**
     * est count in the cdr
     */
    countValue?: number;
    /**
     * est source count in the cdr
     */
    sourceCountValue?: number;
    /**
     * prevalence among participants percent count divided num participants
     */
    prevalence?: number;
    /**
     * concept synonym names
     */
    conceptSynonyms?: Array<string>;
    /**
     * filters clickable concepts
     */
    canSelect?: number;
    measurementConceptInfo?: MeasurementConceptInfo;
    /**
     * drug brand names
     */
    drugBrands?: Array<string>;
    standardConcepts?: Array<Concept>;
    matchType?: MatchType;
}
export namespace Concept {
}


