/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GenomicFilters } from './GenomicFilters';
import {
    GenomicFiltersFromJSON,
    GenomicFiltersFromJSONTyped,
    GenomicFiltersToJSON,
} from './GenomicFilters';
import type { SortMetadata } from './SortMetadata';
import {
    SortMetadataFromJSON,
    SortMetadataFromJSONTyped,
    SortMetadataToJSON,
} from './SortMetadata';

/**
 * 
 * @export
 * @interface SearchVariantsRequest
 */
export interface SearchVariantsRequest {
    /**
     * A query string that can be used to match a subset of the name (case-insensitively), the entire code value (case-insensitively), or the concept ID.
     * 
     * @type {string}
     * @memberof SearchVariantsRequest
     */
    query: string;
    /**
     * By default it returns the first page and then its next pages from that on.
     * @type {number}
     * @memberof SearchVariantsRequest
     */
    pageNumber?: number;
    /**
     * Number of rows user wants to view per page
     * @type {number}
     * @memberof SearchVariantsRequest
     */
    rowCount?: number;
    /**
     * 
     * @type {SortMetadata}
     * @memberof SearchVariantsRequest
     */
    sortMetadata?: SortMetadata;
    /**
     * 
     * @type {GenomicFilters}
     * @memberof SearchVariantsRequest
     */
    filterMetadata?: GenomicFilters;
}

/**
 * Check if a given object implements the SearchVariantsRequest interface.
 */
export function instanceOfSearchVariantsRequest(value: object): value is SearchVariantsRequest {
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function SearchVariantsRequestFromJSON(json: any): SearchVariantsRequest {
    return SearchVariantsRequestFromJSONTyped(json, false);
}

export function SearchVariantsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchVariantsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'rowCount': json['rowCount'] == null ? undefined : json['rowCount'],
        'sortMetadata': json['sortMetadata'] == null ? undefined : SortMetadataFromJSON(json['sortMetadata']),
        'filterMetadata': json['filterMetadata'] == null ? undefined : GenomicFiltersFromJSON(json['filterMetadata']),
    };
}

export function SearchVariantsRequestToJSON(value?: SearchVariantsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'pageNumber': value['pageNumber'],
        'rowCount': value['rowCount'],
        'sortMetadata': SortMetadataToJSON(value['sortMetadata']),
        'filterMetadata': GenomicFiltersToJSON(value['filterMetadata']),
    };
}

