/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenomicSearchTermType
 */
export interface GenomicSearchTermType {
    /**
     * variant property to build sql query
     * @type {string}
     * @memberof GenomicSearchTermType
     */
    variantId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenomicSearchTermType
     */
    whereVariantIdFlag?: boolean;
    /**
     * contig property to build sql query
     * @type {string}
     * @memberof GenomicSearchTermType
     */
    contig?: string;
    /**
     * position low
     * @type {number}
     * @memberof GenomicSearchTermType
     */
    low?: number;
    /**
     * position high
     * @type {number}
     * @memberof GenomicSearchTermType
     */
    high?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenomicSearchTermType
     */
    whereContigFlag?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenomicSearchTermType
     */
    wherePositionFlag?: boolean;
    /**
     * rsnumber property to build sql query
     * @type {string}
     * @memberof GenomicSearchTermType
     */
    rsId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenomicSearchTermType
     */
    whereRsIdFlag?: boolean;
    /**
     * genes property to build sql query
     * @type {string}
     * @memberof GenomicSearchTermType
     */
    genes?: string;
    /**
     * flag to determine if the search type is gene
     * @type {boolean}
     * @memberof GenomicSearchTermType
     */
    whereGeneFlag?: boolean;
    /**
     * sql query formed
     * @type {string}
     * @memberof GenomicSearchTermType
     */
    searchSqlQuery?: string;
}

/**
 * Check if a given object implements the GenomicSearchTermType interface.
 */
export function instanceOfGenomicSearchTermType(value: object): value is GenomicSearchTermType {
    return true;
}

export function GenomicSearchTermTypeFromJSON(json: any): GenomicSearchTermType {
    return GenomicSearchTermTypeFromJSONTyped(json, false);
}

export function GenomicSearchTermTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenomicSearchTermType {
    if (json == null) {
        return json;
    }
    return {
        
        'variantId': json['variant_id'] == null ? undefined : json['variant_id'],
        'whereVariantIdFlag': json['whereVariantIdFlag'] == null ? undefined : json['whereVariantIdFlag'],
        'contig': json['contig'] == null ? undefined : json['contig'],
        'low': json['low'] == null ? undefined : json['low'],
        'high': json['high'] == null ? undefined : json['high'],
        'whereContigFlag': json['whereContigFlag'] == null ? undefined : json['whereContigFlag'],
        'wherePositionFlag': json['wherePositionFlag'] == null ? undefined : json['wherePositionFlag'],
        'rsId': json['rs_id'] == null ? undefined : json['rs_id'],
        'whereRsIdFlag': json['whereRsIdFlag'] == null ? undefined : json['whereRsIdFlag'],
        'genes': json['genes'] == null ? undefined : json['genes'],
        'whereGeneFlag': json['whereGeneFlag'] == null ? undefined : json['whereGeneFlag'],
        'searchSqlQuery': json['searchSqlQuery'] == null ? undefined : json['searchSqlQuery'],
    };
}

export function GenomicSearchTermTypeToJSON(value?: GenomicSearchTermType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variant_id': value['variantId'],
        'whereVariantIdFlag': value['whereVariantIdFlag'],
        'contig': value['contig'],
        'low': value['low'],
        'high': value['high'],
        'whereContigFlag': value['whereContigFlag'],
        'wherePositionFlag': value['wherePositionFlag'],
        'rs_id': value['rsId'],
        'whereRsIdFlag': value['whereRsIdFlag'],
        'genes': value['genes'],
        'whereGeneFlag': value['whereGeneFlag'],
        'searchSqlQuery': value['searchSqlQuery'],
    };
}

