/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SVGenomicFilters } from './SVGenomicFilters';
import {
    SVGenomicFiltersFromJSON,
    SVGenomicFiltersFromJSONTyped,
    SVGenomicFiltersToJSON,
} from './SVGenomicFilters';

/**
 * 
 * @export
 * @interface SVVariantResultSizeRequest
 */
export interface SVVariantResultSizeRequest {
    /**
     * A query string that can be used to match a subset of the name (case-insensitively), the entire code value (case-insensitively), or the concept ID.
     * 
     * @type {string}
     * @memberof SVVariantResultSizeRequest
     */
    query: string;
    /**
     * 
     * @type {SVGenomicFilters}
     * @memberof SVVariantResultSizeRequest
     */
    filterMetadata?: SVGenomicFilters;
}

/**
 * Check if a given object implements the SVVariantResultSizeRequest interface.
 */
export function instanceOfSVVariantResultSizeRequest(value: object): value is SVVariantResultSizeRequest {
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function SVVariantResultSizeRequestFromJSON(json: any): SVVariantResultSizeRequest {
    return SVVariantResultSizeRequestFromJSONTyped(json, false);
}

export function SVVariantResultSizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SVVariantResultSizeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'filterMetadata': json['filterMetadata'] == null ? undefined : SVGenomicFiltersFromJSON(json['filterMetadata']),
    };
}

export function SVVariantResultSizeRequestToJSON(value?: SVVariantResultSizeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'filterMetadata': SVGenomicFiltersToJSON(value['filterMetadata']),
    };
}

