/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AchillesResult
 */
export interface AchillesResult {
    /**
     * pk id for jpa code to work
     * @type {number}
     * @memberof AchillesResult
     */
    id?: number;
    /**
     * id analysis
     * @type {number}
     * @memberof AchillesResult
     */
    analysisId: number;
    /**
     * stratum 1
     * @type {string}
     * @memberof AchillesResult
     */
    stratum1?: string;
    /**
     * stratum 2
     * @type {string}
     * @memberof AchillesResult
     */
    stratum2?: string;
    /**
     * stratum 3
     * @type {string}
     * @memberof AchillesResult
     */
    stratum3?: string;
    /**
     * stratum 4
     * @type {string}
     * @memberof AchillesResult
     */
    stratum4?: string;
    /**
     * stratum 5
     * @type {string}
     * @memberof AchillesResult
     */
    stratum5?: string;
    /**
     * stratum6
     * @type {string}
     * @memberof AchillesResult
     */
    stratum6?: string;
    /**
     * stratum7
     * @type {string}
     * @memberof AchillesResult
     */
    stratum7?: string;
    /**
     * stratum 5 Name
     * @type {string}
     * @memberof AchillesResult
     */
    analysisStratumName?: string;
    /**
     * measurement value type (text / numeric)
     * @type {string}
     * @memberof AchillesResult
     */
    measurementValueType?: string;
    /**
     * count
     * @type {number}
     * @memberof AchillesResult
     */
    countValue?: number;
    /**
     * source count
     * @type {number}
     * @memberof AchillesResult
     */
    sourceCountValue?: number;
}

/**
 * Check if a given object implements the AchillesResult interface.
 */
export function instanceOfAchillesResult(value: object): value is AchillesResult {
    if (!('analysisId' in value) || value['analysisId'] === undefined) return false;
    return true;
}

export function AchillesResultFromJSON(json: any): AchillesResult {
    return AchillesResultFromJSONTyped(json, false);
}

export function AchillesResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AchillesResult {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'analysisId': json['analysisId'],
        'stratum1': json['stratum1'] == null ? undefined : json['stratum1'],
        'stratum2': json['stratum2'] == null ? undefined : json['stratum2'],
        'stratum3': json['stratum3'] == null ? undefined : json['stratum3'],
        'stratum4': json['stratum4'] == null ? undefined : json['stratum4'],
        'stratum5': json['stratum5'] == null ? undefined : json['stratum5'],
        'stratum6': json['stratum6'] == null ? undefined : json['stratum6'],
        'stratum7': json['stratum7'] == null ? undefined : json['stratum7'],
        'analysisStratumName': json['analysisStratumName'] == null ? undefined : json['analysisStratumName'],
        'measurementValueType': json['measurementValueType'] == null ? undefined : json['measurementValueType'],
        'countValue': json['countValue'] == null ? undefined : json['countValue'],
        'sourceCountValue': json['sourceCountValue'] == null ? undefined : json['sourceCountValue'],
    };
}

export function AchillesResultToJSON(value?: AchillesResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'analysisId': value['analysisId'],
        'stratum1': value['stratum1'],
        'stratum2': value['stratum2'],
        'stratum3': value['stratum3'],
        'stratum4': value['stratum4'],
        'stratum5': value['stratum5'],
        'stratum6': value['stratum6'],
        'stratum7': value['stratum7'],
        'analysisStratumName': value['analysisStratumName'],
        'measurementValueType': value['measurementValueType'],
        'countValue': value['countValue'],
        'sourceCountValue': value['sourceCountValue'],
    };
}

