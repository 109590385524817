/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AnalysisListResponse } from './AnalysisListResponse';
import {
    AnalysisListResponseFromJSON,
    AnalysisListResponseFromJSONTyped,
    AnalysisListResponseToJSON,
} from './AnalysisListResponse';

/**
 * 
 * @export
 * @interface SurveyVersionCountResponse
 */
export interface SurveyVersionCountResponse {
    /**
     * 
     * @type {AnalysisListResponse}
     * @memberof SurveyVersionCountResponse
     */
    analyses?: AnalysisListResponse;
}

/**
 * Check if a given object implements the SurveyVersionCountResponse interface.
 */
export function instanceOfSurveyVersionCountResponse(value: object): value is SurveyVersionCountResponse {
    return true;
}

export function SurveyVersionCountResponseFromJSON(json: any): SurveyVersionCountResponse {
    return SurveyVersionCountResponseFromJSONTyped(json, false);
}

export function SurveyVersionCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyVersionCountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'analyses': json['analyses'] == null ? undefined : AnalysisListResponseFromJSON(json['analyses']),
    };
}

export function SurveyVersionCountResponseToJSON(value?: SurveyVersionCountResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'analyses': AnalysisListResponseToJSON(value['analyses']),
    };
}

