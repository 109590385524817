/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SVGenomicSearchTermType { 
    /**
     * variant property to build sql query
     */
    variant_id?: string;
    whereVariantIdFlag?: boolean;
    /**
     * genes property to build sql query
     */
    genes?: string;
    /**
     * flag to determine if the search type is gene
     */
    whereGeneFlag?: boolean;
    /**
     * contig property to build sql query
     */
    contig?: string;
    /**
     * position low
     */
    low?: number;
    /**
     * position high
     */
    high?: number;
    whereContigFlag?: boolean;
    wherePositionFlag?: boolean;
    /**
     * sql query formed
     */
    searchSqlQuery?: string;
}

