/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenomicFilterOption } from './genomicFilterOption';


export interface GenomicFilterOptionList { 
    items: Array<GenomicFilterOption>;
    /**
     * boolean flag to know whether the filter is active
     */
    filterActive?: boolean;
}

