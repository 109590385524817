/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandardConceptFilter } from './StandardConceptFilter';
import {
    StandardConceptFilterFromJSON,
    StandardConceptFilterFromJSONTyped,
    StandardConceptFilterToJSON,
} from './StandardConceptFilter';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';

/**
 * 
 * @export
 * @interface SearchConceptsRequest
 */
export interface SearchConceptsRequest {
    /**
     * A query string that can be used to match a subset of the name (case-insensitively), the entire code value (case-insensitively), or the concept ID.
     * 
     * @type {string}
     * @memberof SearchConceptsRequest
     */
    query: string;
    /**
     * 
     * @type {StandardConceptFilter}
     * @memberof SearchConceptsRequest
     */
    standardConceptFilter?: StandardConceptFilter;
    /**
     * The vocabulary ID for the concepts returned (e.g. SNOMED, RxNorm)
     * @type {Array<string>}
     * @memberof SearchConceptsRequest
     */
    vocabularyIds?: Array<string>;
    /**
     * 
     * @type {Domain}
     * @memberof SearchConceptsRequest
     */
    domain?: Domain;
    /**
     * The maximum number of results returned. Defaults to 25.
     * @type {number}
     * @memberof SearchConceptsRequest
     */
    maxResults?: number;
    /**
     * The minimum count of concepts to be fetched
     * @type {number}
     * @memberof SearchConceptsRequest
     */
    minCount?: number;
    /**
     * By default it returns the first page and then its next pages from that on.
     * @type {number}
     * @memberof SearchConceptsRequest
     */
    pageNumber?: number;
    /**
     * By default all the measurement tests are returned
     * @type {number}
     * @memberof SearchConceptsRequest
     */
    measurementTests?: number;
    /**
     * By default all the measurement orders are returned
     * @type {number}
     * @memberof SearchConceptsRequest
     */
    measurementOrders?: number;
}

/**
 * Check if a given object implements the SearchConceptsRequest interface.
 */
export function instanceOfSearchConceptsRequest(value: object): value is SearchConceptsRequest {
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function SearchConceptsRequestFromJSON(json: any): SearchConceptsRequest {
    return SearchConceptsRequestFromJSONTyped(json, false);
}

export function SearchConceptsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchConceptsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'standardConceptFilter': json['standardConceptFilter'] == null ? undefined : StandardConceptFilterFromJSON(json['standardConceptFilter']),
        'vocabularyIds': json['vocabularyIds'] == null ? undefined : json['vocabularyIds'],
        'domain': json['domain'] == null ? undefined : DomainFromJSON(json['domain']),
        'maxResults': json['maxResults'] == null ? undefined : json['maxResults'],
        'minCount': json['minCount'] == null ? undefined : json['minCount'],
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'measurementTests': json['measurementTests'] == null ? undefined : json['measurementTests'],
        'measurementOrders': json['measurementOrders'] == null ? undefined : json['measurementOrders'],
    };
}

export function SearchConceptsRequestToJSON(value?: SearchConceptsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'standardConceptFilter': StandardConceptFilterToJSON(value['standardConceptFilter']),
        'vocabularyIds': value['vocabularyIds'],
        'domain': DomainToJSON(value['domain']),
        'maxResults': value['maxResults'],
        'minCount': value['minCount'],
        'pageNumber': value['pageNumber'],
        'measurementTests': value['measurementTests'],
        'measurementOrders': value['measurementOrders'],
    };
}

