/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortColumnDetails } from './sortColumnDetails';


export interface SortMetadata { 
    variantId?: SortColumnDetails;
    gene?: SortColumnDetails;
    consequence?: SortColumnDetails;
    variantType?: SortColumnDetails;
    clinicalSignificance?: SortColumnDetails;
    alleleCount?: SortColumnDetails;
    alleleNumber?: SortColumnDetails;
    alleleFrequency?: SortColumnDetails;
    homozygoteCount?: SortColumnDetails;
}

