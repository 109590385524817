/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GenomicFilterOption } from './GenomicFilterOption';
import {
    GenomicFilterOptionFromJSON,
    GenomicFilterOptionFromJSONTyped,
    GenomicFilterOptionToJSON,
} from './GenomicFilterOption';
import type { GenomicFilterOptionList } from './GenomicFilterOptionList';
import {
    GenomicFilterOptionListFromJSON,
    GenomicFilterOptionListFromJSONTyped,
    GenomicFilterOptionListToJSON,
} from './GenomicFilterOptionList';

/**
 * 
 * @export
 * @interface GenomicFilters
 */
export interface GenomicFilters {
    /**
     * 
     * @type {GenomicFilterOptionList}
     * @memberof GenomicFilters
     */
    gene?: GenomicFilterOptionList;
    /**
     * 
     * @type {GenomicFilterOptionList}
     * @memberof GenomicFilters
     */
    consequence?: GenomicFilterOptionList;
    /**
     * 
     * @type {GenomicFilterOptionList}
     * @memberof GenomicFilters
     */
    variantType?: GenomicFilterOptionList;
    /**
     * 
     * @type {GenomicFilterOptionList}
     * @memberof GenomicFilters
     */
    clinicalSignificance?: GenomicFilterOptionList;
    /**
     * 
     * @type {GenomicFilterOption}
     * @memberof GenomicFilters
     */
    alleleCount?: GenomicFilterOption;
    /**
     * 
     * @type {GenomicFilterOption}
     * @memberof GenomicFilters
     */
    alleleNumber?: GenomicFilterOption;
    /**
     * 
     * @type {GenomicFilterOption}
     * @memberof GenomicFilters
     */
    alleleFrequency?: GenomicFilterOption;
    /**
     * 
     * @type {GenomicFilterOption}
     * @memberof GenomicFilters
     */
    homozygoteCount?: GenomicFilterOption;
}

/**
 * Check if a given object implements the GenomicFilters interface.
 */
export function instanceOfGenomicFilters(value: object): value is GenomicFilters {
    return true;
}

export function GenomicFiltersFromJSON(json: any): GenomicFilters {
    return GenomicFiltersFromJSONTyped(json, false);
}

export function GenomicFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenomicFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'gene': json['gene'] == null ? undefined : GenomicFilterOptionListFromJSON(json['gene']),
        'consequence': json['consequence'] == null ? undefined : GenomicFilterOptionListFromJSON(json['consequence']),
        'variantType': json['variantType'] == null ? undefined : GenomicFilterOptionListFromJSON(json['variantType']),
        'clinicalSignificance': json['clinicalSignificance'] == null ? undefined : GenomicFilterOptionListFromJSON(json['clinicalSignificance']),
        'alleleCount': json['alleleCount'] == null ? undefined : GenomicFilterOptionFromJSON(json['alleleCount']),
        'alleleNumber': json['alleleNumber'] == null ? undefined : GenomicFilterOptionFromJSON(json['alleleNumber']),
        'alleleFrequency': json['alleleFrequency'] == null ? undefined : GenomicFilterOptionFromJSON(json['alleleFrequency']),
        'homozygoteCount': json['homozygoteCount'] == null ? undefined : GenomicFilterOptionFromJSON(json['homozygoteCount']),
    };
}

export function GenomicFiltersToJSON(value?: GenomicFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gene': GenomicFilterOptionListToJSON(value['gene']),
        'consequence': GenomicFilterOptionListToJSON(value['consequence']),
        'variantType': GenomicFilterOptionListToJSON(value['variantType']),
        'clinicalSignificance': GenomicFilterOptionListToJSON(value['clinicalSignificance']),
        'alleleCount': GenomicFilterOptionToJSON(value['alleleCount']),
        'alleleNumber': GenomicFilterOptionToJSON(value['alleleNumber']),
        'alleleFrequency': GenomicFilterOptionToJSON(value['alleleFrequency']),
        'homozygoteCount': GenomicFilterOptionToJSON(value['homozygoteCount']),
    };
}

