/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MatchType } from './MatchType';
import {
    MatchTypeFromJSON,
    MatchTypeFromJSONTyped,
    MatchTypeToJSON,
} from './MatchType';
import type { MeasurementConceptInfo } from './MeasurementConceptInfo';
import {
    MeasurementConceptInfoFromJSON,
    MeasurementConceptInfoFromJSONTyped,
    MeasurementConceptInfoToJSON,
} from './MeasurementConceptInfo';

/**
 * 
 * @export
 * @interface Concept
 */
export interface Concept {
    /**
     * id of the concept
     * @type {number}
     * @memberof Concept
     */
    conceptId: number;
    /**
     * name of concept
     * @type {string}
     * @memberof Concept
     */
    conceptName: string;
    /**
     * domain of concept
     * @type {string}
     * @memberof Concept
     */
    domainId: string;
    /**
     * vocabulary of concept
     * @type {string}
     * @memberof Concept
     */
    vocabularyId: string;
    /**
     * original vocab code of concept
     * @type {string}
     * @memberof Concept
     */
    conceptCode: string;
    /**
     * class of concept
     * @type {string}
     * @memberof Concept
     */
    conceptClassId: string;
    /**
     * standard concept value 1 char
     * @type {string}
     * @memberof Concept
     */
    standardConcept: string;
    /**
     * est count in the cdr
     * @type {number}
     * @memberof Concept
     */
    countValue?: number;
    /**
     * est source count in the cdr
     * @type {number}
     * @memberof Concept
     */
    sourceCountValue?: number;
    /**
     * prevalence among participants percent count divided num participants
     * @type {number}
     * @memberof Concept
     */
    prevalence?: number;
    /**
     * concept synonym names
     * @type {Array<string>}
     * @memberof Concept
     */
    conceptSynonyms?: Array<string>;
    /**
     * filters clickable concepts
     * @type {number}
     * @memberof Concept
     */
    canSelect?: number;
    /**
     * 
     * @type {MeasurementConceptInfo}
     * @memberof Concept
     */
    measurementConceptInfo?: MeasurementConceptInfo;
    /**
     * drug brand names
     * @type {Array<string>}
     * @memberof Concept
     */
    drugBrands?: Array<string>;
    /**
     * 
     * @type {Array<Concept>}
     * @memberof Concept
     */
    standardConcepts?: Array<Concept>;
    /**
     * 
     * @type {MatchType}
     * @memberof Concept
     */
    matchType?: MatchType;
}

/**
 * Check if a given object implements the Concept interface.
 */
export function instanceOfConcept(value: object): value is Concept {
    if (!('conceptId' in value) || value['conceptId'] === undefined) return false;
    if (!('conceptName' in value) || value['conceptName'] === undefined) return false;
    if (!('domainId' in value) || value['domainId'] === undefined) return false;
    if (!('vocabularyId' in value) || value['vocabularyId'] === undefined) return false;
    if (!('conceptCode' in value) || value['conceptCode'] === undefined) return false;
    if (!('conceptClassId' in value) || value['conceptClassId'] === undefined) return false;
    if (!('standardConcept' in value) || value['standardConcept'] === undefined) return false;
    return true;
}

export function ConceptFromJSON(json: any): Concept {
    return ConceptFromJSONTyped(json, false);
}

export function ConceptFromJSONTyped(json: any, ignoreDiscriminator: boolean): Concept {
    if (json == null) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'],
        'conceptName': json['conceptName'],
        'domainId': json['domainId'],
        'vocabularyId': json['vocabularyId'],
        'conceptCode': json['conceptCode'],
        'conceptClassId': json['conceptClassId'],
        'standardConcept': json['standardConcept'],
        'countValue': json['countValue'] == null ? undefined : json['countValue'],
        'sourceCountValue': json['sourceCountValue'] == null ? undefined : json['sourceCountValue'],
        'prevalence': json['prevalence'] == null ? undefined : json['prevalence'],
        'conceptSynonyms': json['conceptSynonyms'] == null ? undefined : json['conceptSynonyms'],
        'canSelect': json['canSelect'] == null ? undefined : json['canSelect'],
        'measurementConceptInfo': json['measurementConceptInfo'] == null ? undefined : MeasurementConceptInfoFromJSON(json['measurementConceptInfo']),
        'drugBrands': json['drugBrands'] == null ? undefined : json['drugBrands'],
        'standardConcepts': json['standardConcepts'] == null ? undefined : ((json['standardConcepts'] as Array<any>).map(ConceptFromJSON)),
        'matchType': json['matchType'] == null ? undefined : MatchTypeFromJSON(json['matchType']),
    };
}

export function ConceptToJSON(value?: Concept | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conceptId': value['conceptId'],
        'conceptName': value['conceptName'],
        'domainId': value['domainId'],
        'vocabularyId': value['vocabularyId'],
        'conceptCode': value['conceptCode'],
        'conceptClassId': value['conceptClassId'],
        'standardConcept': value['standardConcept'],
        'countValue': value['countValue'],
        'sourceCountValue': value['sourceCountValue'],
        'prevalence': value['prevalence'],
        'conceptSynonyms': value['conceptSynonyms'],
        'canSelect': value['canSelect'],
        'measurementConceptInfo': MeasurementConceptInfoToJSON(value['measurementConceptInfo']),
        'drugBrands': value['drugBrands'],
        'standardConcepts': value['standardConcepts'] == null ? undefined : ((value['standardConcepts'] as Array<any>).map(ConceptToJSON)),
        'matchType': MatchTypeToJSON(value['matchType']),
    };
}

