/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SVGenomicFilterOptionList } from './sVGenomicFilterOptionList';
import { SVGenomicFilterOption } from './sVGenomicFilterOption';


export interface SVGenomicFilters { 
    gene?: SVGenomicFilterOptionList;
    consequence?: SVGenomicFilterOptionList;
    variantType?: SVGenomicFilterOptionList;
    size?: SVGenomicFilterOption;
    alleleCount?: SVGenomicFilterOption;
    alleleNumber?: SVGenomicFilterOption;
    alleleFrequency?: SVGenomicFilterOption;
    homozygoteCount?: SVGenomicFilterOption;
}

