/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortColumnDetails } from './SortColumnDetails';
import {
    SortColumnDetailsFromJSON,
    SortColumnDetailsFromJSONTyped,
    SortColumnDetailsToJSON,
} from './SortColumnDetails';

/**
 * 
 * @export
 * @interface SortSVMetadata
 */
export interface SortSVMetadata {
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    variantId?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    variantType?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    consequence?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    position?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    size?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    alleleCount?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    alleleNumber?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    alleleFrequency?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortSVMetadata
     */
    homozygoteCount?: SortColumnDetails;
}

/**
 * Check if a given object implements the SortSVMetadata interface.
 */
export function instanceOfSortSVMetadata(value: object): value is SortSVMetadata {
    return true;
}

export function SortSVMetadataFromJSON(json: any): SortSVMetadata {
    return SortSVMetadataFromJSONTyped(json, false);
}

export function SortSVMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortSVMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'variantId': json['variantId'] == null ? undefined : SortColumnDetailsFromJSON(json['variantId']),
        'variantType': json['variantType'] == null ? undefined : SortColumnDetailsFromJSON(json['variantType']),
        'consequence': json['consequence'] == null ? undefined : SortColumnDetailsFromJSON(json['consequence']),
        'position': json['position'] == null ? undefined : SortColumnDetailsFromJSON(json['position']),
        'size': json['size'] == null ? undefined : SortColumnDetailsFromJSON(json['size']),
        'alleleCount': json['alleleCount'] == null ? undefined : SortColumnDetailsFromJSON(json['alleleCount']),
        'alleleNumber': json['alleleNumber'] == null ? undefined : SortColumnDetailsFromJSON(json['alleleNumber']),
        'alleleFrequency': json['alleleFrequency'] == null ? undefined : SortColumnDetailsFromJSON(json['alleleFrequency']),
        'homozygoteCount': json['homozygoteCount'] == null ? undefined : SortColumnDetailsFromJSON(json['homozygoteCount']),
    };
}

export function SortSVMetadataToJSON(value?: SortSVMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variantId': SortColumnDetailsToJSON(value['variantId']),
        'variantType': SortColumnDetailsToJSON(value['variantType']),
        'consequence': SortColumnDetailsToJSON(value['consequence']),
        'position': SortColumnDetailsToJSON(value['position']),
        'size': SortColumnDetailsToJSON(value['size']),
        'alleleCount': SortColumnDetailsToJSON(value['alleleCount']),
        'alleleNumber': SortColumnDetailsToJSON(value['alleleNumber']),
        'alleleFrequency': SortColumnDetailsToJSON(value['alleleFrequency']),
        'homozygoteCount': SortColumnDetailsToJSON(value['homozygoteCount']),
    };
}

