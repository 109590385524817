/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortColumnDetails } from './SortColumnDetails';
import {
    SortColumnDetailsFromJSON,
    SortColumnDetailsFromJSONTyped,
    SortColumnDetailsToJSON,
} from './SortColumnDetails';

/**
 * 
 * @export
 * @interface SortMetadata
 */
export interface SortMetadata {
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    variantId?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    gene?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    consequence?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    variantType?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    clinicalSignificance?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    alleleCount?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    alleleNumber?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    alleleFrequency?: SortColumnDetails;
    /**
     * 
     * @type {SortColumnDetails}
     * @memberof SortMetadata
     */
    homozygoteCount?: SortColumnDetails;
}

/**
 * Check if a given object implements the SortMetadata interface.
 */
export function instanceOfSortMetadata(value: object): value is SortMetadata {
    return true;
}

export function SortMetadataFromJSON(json: any): SortMetadata {
    return SortMetadataFromJSONTyped(json, false);
}

export function SortMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'variantId': json['variantId'] == null ? undefined : SortColumnDetailsFromJSON(json['variantId']),
        'gene': json['gene'] == null ? undefined : SortColumnDetailsFromJSON(json['gene']),
        'consequence': json['consequence'] == null ? undefined : SortColumnDetailsFromJSON(json['consequence']),
        'variantType': json['variantType'] == null ? undefined : SortColumnDetailsFromJSON(json['variantType']),
        'clinicalSignificance': json['clinicalSignificance'] == null ? undefined : SortColumnDetailsFromJSON(json['clinicalSignificance']),
        'alleleCount': json['alleleCount'] == null ? undefined : SortColumnDetailsFromJSON(json['alleleCount']),
        'alleleNumber': json['alleleNumber'] == null ? undefined : SortColumnDetailsFromJSON(json['alleleNumber']),
        'alleleFrequency': json['alleleFrequency'] == null ? undefined : SortColumnDetailsFromJSON(json['alleleFrequency']),
        'homozygoteCount': json['homozygoteCount'] == null ? undefined : SortColumnDetailsFromJSON(json['homozygoteCount']),
    };
}

export function SortMetadataToJSON(value?: SortMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variantId': SortColumnDetailsToJSON(value['variantId']),
        'gene': SortColumnDetailsToJSON(value['gene']),
        'consequence': SortColumnDetailsToJSON(value['consequence']),
        'variantType': SortColumnDetailsToJSON(value['variantType']),
        'clinicalSignificance': SortColumnDetailsToJSON(value['clinicalSignificance']),
        'alleleCount': SortColumnDetailsToJSON(value['alleleCount']),
        'alleleNumber': SortColumnDetailsToJSON(value['alleleNumber']),
        'alleleFrequency': SortColumnDetailsToJSON(value['alleleFrequency']),
        'homozygoteCount': SortColumnDetailsToJSON(value['homozygoteCount']),
    };
}

