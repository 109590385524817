/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigResponse { 
    /**
     * The cloud project in which this app is running.
     */
    projectId?: string;
    /**
     * Whether the data is safe to show. An emergency page will show if set to false.
     */
    dataBrowserIsSafe?: boolean;
    /**
     * Stackdriver API key for error reporting, scoped to a particular domain. If unset, Stackdriver error reporting should be disabled. 
     */
    publicApiKeyForErrorReports?: string;
}

