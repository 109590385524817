/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';

/**
 * 
 * @export
 * @interface DomainInfo
 */
export interface DomainInfo {
    /**
     * 
     * @type {Domain}
     * @memberof DomainInfo
     */
    domain: Domain;
    /**
     * domain concept id
     * @type {number}
     * @memberof DomainInfo
     */
    domainConceptId?: number;
    /**
     * display name of the domain
     * @type {string}
     * @memberof DomainInfo
     */
    name: string;
    /**
     * description of the domain
     * @type {string}
     * @memberof DomainInfo
     */
    description: string;
    /**
     * number of standard concepts in this domain
     * @type {number}
     * @memberof DomainInfo
     */
    standardConceptCount: number;
    /**
     * number of concepts in this domain (standard or non-standard)
     * @type {number}
     * @memberof DomainInfo
     */
    allConceptCount: number;
    /**
     * number of participants with data in the CDR for a concept in this domain
     * @type {number}
     * @memberof DomainInfo
     */
    participantCount: number;
}

/**
 * Check if a given object implements the DomainInfo interface.
 */
export function instanceOfDomainInfo(value: object): value is DomainInfo {
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('standardConceptCount' in value) || value['standardConceptCount'] === undefined) return false;
    if (!('allConceptCount' in value) || value['allConceptCount'] === undefined) return false;
    if (!('participantCount' in value) || value['participantCount'] === undefined) return false;
    return true;
}

export function DomainInfoFromJSON(json: any): DomainInfo {
    return DomainInfoFromJSONTyped(json, false);
}

export function DomainInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'domain': DomainFromJSON(json['domain']),
        'domainConceptId': json['domainConceptId'] == null ? undefined : json['domainConceptId'],
        'name': json['name'],
        'description': json['description'],
        'standardConceptCount': json['standardConceptCount'],
        'allConceptCount': json['allConceptCount'],
        'participantCount': json['participantCount'],
    };
}

export function DomainInfoToJSON(value?: DomainInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'domain': DomainToJSON(value['domain']),
        'domainConceptId': value['domainConceptId'],
        'name': value['name'],
        'description': value['description'],
        'standardConceptCount': value['standardConceptCount'],
        'allConceptCount': value['allConceptCount'],
        'participantCount': value['participantCount'],
    };
}

