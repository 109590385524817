/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SVVariant } from './SVVariant';
import {
    SVVariantFromJSON,
    SVVariantFromJSONTyped,
    SVVariantToJSON,
} from './SVVariant';

/**
 * 
 * @export
 * @interface SVVariantListResponse
 */
export interface SVVariantListResponse {
    /**
     * 
     * @type {Array<SVVariant>}
     * @memberof SVVariantListResponse
     */
    items: Array<SVVariant>;
}

/**
 * Check if a given object implements the SVVariantListResponse interface.
 */
export function instanceOfSVVariantListResponse(value: object): value is SVVariantListResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function SVVariantListResponseFromJSON(json: any): SVVariantListResponse {
    return SVVariantListResponseFromJSONTyped(json, false);
}

export function SVVariantListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SVVariantListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SVVariantFromJSON)),
    };
}

export function SVVariantListResponseToJSON(value?: SVVariantListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SVVariantToJSON)),
    };
}

