/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortColumnDetails
 */
export interface SortColumnDetails {
    /**
     * Flag for active sort
     * @type {boolean}
     * @memberof SortColumnDetails
     */
    sortActive?: boolean;
    /**
     * Sort Direction
     * @type {string}
     * @memberof SortColumnDetails
     */
    sortDirection?: string;
    /**
     * Sort Order
     * @type {number}
     * @memberof SortColumnDetails
     */
    sortOrder?: number;
}

/**
 * Check if a given object implements the SortColumnDetails interface.
 */
export function instanceOfSortColumnDetails(value: object): value is SortColumnDetails {
    return true;
}

export function SortColumnDetailsFromJSON(json: any): SortColumnDetails {
    return SortColumnDetailsFromJSONTyped(json, false);
}

export function SortColumnDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortColumnDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'sortActive': json['sortActive'] == null ? undefined : json['sortActive'],
        'sortDirection': json['sortDirection'] == null ? undefined : json['sortDirection'],
        'sortOrder': json['sortOrder'] == null ? undefined : json['sortOrder'],
    };
}

export function SortColumnDetailsToJSON(value?: SortColumnDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sortActive': value['sortActive'],
        'sortDirection': value['sortDirection'],
        'sortOrder': value['sortOrder'],
    };
}

