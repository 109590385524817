/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SurveyModule } from './SurveyModule';
import {
    SurveyModuleFromJSON,
    SurveyModuleFromJSONTyped,
    SurveyModuleToJSON,
} from './SurveyModule';
import type { SurveyMetadataListResponse } from './SurveyMetadataListResponse';
import {
    SurveyMetadataListResponseFromJSON,
    SurveyMetadataListResponseFromJSONTyped,
    SurveyMetadataListResponseToJSON,
} from './SurveyMetadataListResponse';

/**
 * 
 * @export
 * @interface SurveyQuestionFetchResponse
 */
export interface SurveyQuestionFetchResponse {
    /**
     * 
     * @type {SurveyMetadataListResponse}
     * @memberof SurveyQuestionFetchResponse
     */
    questions?: SurveyMetadataListResponse;
    /**
     * 
     * @type {SurveyModule}
     * @memberof SurveyQuestionFetchResponse
     */
    survey?: SurveyModule;
}

/**
 * Check if a given object implements the SurveyQuestionFetchResponse interface.
 */
export function instanceOfSurveyQuestionFetchResponse(value: object): value is SurveyQuestionFetchResponse {
    return true;
}

export function SurveyQuestionFetchResponseFromJSON(json: any): SurveyQuestionFetchResponse {
    return SurveyQuestionFetchResponseFromJSONTyped(json, false);
}

export function SurveyQuestionFetchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyQuestionFetchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'questions': json['questions'] == null ? undefined : SurveyMetadataListResponseFromJSON(json['questions']),
        'survey': json['survey'] == null ? undefined : SurveyModuleFromJSON(json['survey']),
    };
}

export function SurveyQuestionFetchResponseToJSON(value?: SurveyQuestionFetchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'questions': SurveyMetadataListResponseToJSON(value['questions']),
        'survey': SurveyModuleToJSON(value['survey']),
    };
}

