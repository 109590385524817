/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CdrVersion
 */
export interface CdrVersion {
    /**
     * 
     * @type {string}
     * @memberof CdrVersion
     */
    cdrVersionId: string;
    /**
     * 
     * @type {string}
     * @memberof CdrVersion
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CdrVersion
     */
    numParticipants?: number;
    /**
     * Milliseconds since the UNIX epoch.
     * @type {number}
     * @memberof CdrVersion
     */
    creationTime: number;
}

/**
 * Check if a given object implements the CdrVersion interface.
 */
export function instanceOfCdrVersion(value: object): value is CdrVersion {
    if (!('cdrVersionId' in value) || value['cdrVersionId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('creationTime' in value) || value['creationTime'] === undefined) return false;
    return true;
}

export function CdrVersionFromJSON(json: any): CdrVersion {
    return CdrVersionFromJSONTyped(json, false);
}

export function CdrVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdrVersion {
    if (json == null) {
        return json;
    }
    return {
        
        'cdrVersionId': json['cdrVersionId'],
        'name': json['name'],
        'numParticipants': json['numParticipants'] == null ? undefined : json['numParticipants'],
        'creationTime': json['creationTime'],
    };
}

export function CdrVersionToJSON(value?: CdrVersion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cdrVersionId': value['cdrVersionId'],
        'name': value['name'],
        'numParticipants': value['numParticipants'],
        'creationTime': value['creationTime'],
    };
}

