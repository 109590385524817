/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Variant
 */
export interface Variant {
    /**
     * id variant
     * @type {string}
     * @memberof Variant
     */
    variantId: string;
    /**
     * genes
     * @type {string}
     * @memberof Variant
     */
    genes?: string;
    /**
     * consequence
     * @type {string}
     * @memberof Variant
     */
    consequence?: string;
    /**
     * variant type
     * @type {string}
     * @memberof Variant
     */
    variantType?: string;
    /**
     * protein change
     * @type {string}
     * @memberof Variant
     */
    proteinChange?: string;
    /**
     * clinical significance
     * @type {string}
     * @memberof Variant
     */
    clinicalSignificance?: string;
    /**
     * allele count
     * @type {number}
     * @memberof Variant
     */
    alleleCount?: number;
    /**
     * allele number
     * @type {number}
     * @memberof Variant
     */
    alleleNumber?: number;
    /**
     * allele frequency
     * @type {number}
     * @memberof Variant
     */
    alleleFrequency?: number;
    /**
     * homozygote count
     * @type {number}
     * @memberof Variant
     */
    homozygoteCount?: number;
}

/**
 * Check if a given object implements the Variant interface.
 */
export function instanceOfVariant(value: object): value is Variant {
    if (!('variantId' in value) || value['variantId'] === undefined) return false;
    return true;
}

export function VariantFromJSON(json: any): Variant {
    return VariantFromJSONTyped(json, false);
}

export function VariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Variant {
    if (json == null) {
        return json;
    }
    return {
        
        'variantId': json['variantId'],
        'genes': json['genes'] == null ? undefined : json['genes'],
        'consequence': json['consequence'] == null ? undefined : json['consequence'],
        'variantType': json['variantType'] == null ? undefined : json['variantType'],
        'proteinChange': json['proteinChange'] == null ? undefined : json['proteinChange'],
        'clinicalSignificance': json['clinicalSignificance'] == null ? undefined : json['clinicalSignificance'],
        'alleleCount': json['alleleCount'] == null ? undefined : json['alleleCount'],
        'alleleNumber': json['alleleNumber'] == null ? undefined : json['alleleNumber'],
        'alleleFrequency': json['alleleFrequency'] == null ? undefined : json['alleleFrequency'],
        'homozygoteCount': json['homozygoteCount'] == null ? undefined : json['homozygoteCount'],
    };
}

export function VariantToJSON(value?: Variant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'variantId': value['variantId'],
        'genes': value['genes'],
        'consequence': value['consequence'],
        'variantType': value['variantType'],
        'proteinChange': value['proteinChange'],
        'clinicalSignificance': value['clinicalSignificance'],
        'alleleCount': value['alleleCount'],
        'alleleNumber': value['alleleNumber'],
        'alleleFrequency': value['alleleFrequency'],
        'homozygoteCount': value['homozygoteCount'],
    };
}

