/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AchillesResult } from './AchillesResult';
import {
    AchillesResultFromJSON,
    AchillesResultFromJSONTyped,
    AchillesResultToJSON,
} from './AchillesResult';
import type { AchillesResultDist } from './AchillesResultDist';
import {
    AchillesResultDistFromJSON,
    AchillesResultDistFromJSONTyped,
    AchillesResultDistToJSON,
} from './AchillesResultDist';

/**
 * 
 * @export
 * @interface Analysis
 */
export interface Analysis {
    /**
     * id analysis
     * @type {number}
     * @memberof Analysis
     */
    analysisId: number;
    /**
     * analysis name
     * @type {string}
     * @memberof Analysis
     */
    analysisName?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum1Name?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum2Name?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum3Name?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum4Name?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum5Name?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum6Name?: string;
    /**
     * usually concept name corresponding to stratum
     * @type {string}
     * @memberof Analysis
     */
    stratum7Name?: string;
    /**
     * chart type to display for this analysis column pie box
     * @type {string}
     * @memberof Analysis
     */
    chartType?: string;
    /**
     * data type of this analysis count or distribution
     * @type {string}
     * @memberof Analysis
     */
    dataType?: string;
    /**
     * count results
     * @type {Array<AchillesResult>}
     * @memberof Analysis
     */
    results?: Array<AchillesResult>;
    /**
     * distribution results
     * @type {Array<AchillesResultDist>}
     * @memberof Analysis
     */
    distResults?: Array<AchillesResultDist>;
    /**
     * unit name
     * @type {string}
     * @memberof Analysis
     */
    unitName?: string;
}

/**
 * Check if a given object implements the Analysis interface.
 */
export function instanceOfAnalysis(value: object): value is Analysis {
    if (!('analysisId' in value) || value['analysisId'] === undefined) return false;
    return true;
}

export function AnalysisFromJSON(json: any): Analysis {
    return AnalysisFromJSONTyped(json, false);
}

export function AnalysisFromJSONTyped(json: any, ignoreDiscriminator: boolean): Analysis {
    if (json == null) {
        return json;
    }
    return {
        
        'analysisId': json['analysisId'],
        'analysisName': json['analysisName'] == null ? undefined : json['analysisName'],
        'stratum1Name': json['stratum1Name'] == null ? undefined : json['stratum1Name'],
        'stratum2Name': json['stratum2Name'] == null ? undefined : json['stratum2Name'],
        'stratum3Name': json['stratum3Name'] == null ? undefined : json['stratum3Name'],
        'stratum4Name': json['stratum4Name'] == null ? undefined : json['stratum4Name'],
        'stratum5Name': json['stratum5Name'] == null ? undefined : json['stratum5Name'],
        'stratum6Name': json['stratum6Name'] == null ? undefined : json['stratum6Name'],
        'stratum7Name': json['stratum7Name'] == null ? undefined : json['stratum7Name'],
        'chartType': json['chartType'] == null ? undefined : json['chartType'],
        'dataType': json['dataType'] == null ? undefined : json['dataType'],
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(AchillesResultFromJSON)),
        'distResults': json['distResults'] == null ? undefined : ((json['distResults'] as Array<any>).map(AchillesResultDistFromJSON)),
        'unitName': json['unitName'] == null ? undefined : json['unitName'],
    };
}

export function AnalysisToJSON(value?: Analysis | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'analysisId': value['analysisId'],
        'analysisName': value['analysisName'],
        'stratum1Name': value['stratum1Name'],
        'stratum2Name': value['stratum2Name'],
        'stratum3Name': value['stratum3Name'],
        'stratum4Name': value['stratum4Name'],
        'stratum5Name': value['stratum5Name'],
        'stratum6Name': value['stratum6Name'],
        'stratum7Name': value['stratum7Name'],
        'chartType': value['chartType'],
        'dataType': value['dataType'],
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(AchillesResultToJSON)),
        'distResults': value['distResults'] == null ? undefined : ((value['distResults'] as Array<any>).map(AchillesResultDistToJSON)),
        'unitName': value['unitName'],
    };
}

