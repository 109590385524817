/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Variant { 
    /**
     * id variant
     */
    variantId: string;
    /**
     * genes
     */
    genes?: string;
    /**
     * consequence
     */
    consequence?: string;
    /**
     * variant type
     */
    variantType?: string;
    /**
     * protein change
     */
    proteinChange?: string;
    /**
     * clinical significance
     */
    clinicalSignificance?: string;
    /**
     * allele count
     */
    alleleCount?: number;
    /**
     * allele number
     */
    alleleNumber?: number;
    /**
     * allele frequency
     */
    alleleFrequency?: number;
    /**
     * homozygote count
     */
    homozygoteCount?: number;
}

