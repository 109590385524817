/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Criteria } from './Criteria';
import {
    CriteriaFromJSON,
    CriteriaFromJSONTyped,
    CriteriaToJSON,
} from './Criteria';

/**
 * 
 * @export
 * @interface CriteriaParentResponse
 */
export interface CriteriaParentResponse {
    /**
     * 
     * @type {Criteria}
     * @memberof CriteriaParentResponse
     */
    parent?: Criteria;
}

/**
 * Check if a given object implements the CriteriaParentResponse interface.
 */
export function instanceOfCriteriaParentResponse(value: object): value is CriteriaParentResponse {
    return true;
}

export function CriteriaParentResponseFromJSON(json: any): CriteriaParentResponse {
    return CriteriaParentResponseFromJSONTyped(json, false);
}

export function CriteriaParentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CriteriaParentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'parent': json['parent'] == null ? undefined : CriteriaFromJSON(json['parent']),
    };
}

export function CriteriaParentResponseToJSON(value?: CriteriaParentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'parent': CriteriaToJSON(value['parent']),
    };
}

