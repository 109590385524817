/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GenomicFilters } from './GenomicFilters';
import {
    GenomicFiltersFromJSON,
    GenomicFiltersFromJSONTyped,
    GenomicFiltersToJSON,
} from './GenomicFilters';

/**
 * 
 * @export
 * @interface VariantResultSizeRequest
 */
export interface VariantResultSizeRequest {
    /**
     * A query string that can be used to match a subset of the name (case-insensitively), the entire code value (case-insensitively), or the concept ID.
     * 
     * @type {string}
     * @memberof VariantResultSizeRequest
     */
    query: string;
    /**
     * 
     * @type {GenomicFilters}
     * @memberof VariantResultSizeRequest
     */
    filterMetadata?: GenomicFilters;
}

/**
 * Check if a given object implements the VariantResultSizeRequest interface.
 */
export function instanceOfVariantResultSizeRequest(value: object): value is VariantResultSizeRequest {
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function VariantResultSizeRequestFromJSON(json: any): VariantResultSizeRequest {
    return VariantResultSizeRequestFromJSONTyped(json, false);
}

export function VariantResultSizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantResultSizeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'filterMetadata': json['filterMetadata'] == null ? undefined : GenomicFiltersFromJSON(json['filterMetadata']),
    };
}

export function VariantResultSizeRequestToJSON(value?: VariantResultSizeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'filterMetadata': GenomicFiltersToJSON(value['filterMetadata']),
    };
}

