/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * filter on whether standard, non-standard, or all concepts should be returned\\
 */
export type StandardConceptFilter = 'ALL_CONCEPTS' | 'STANDARD_CONCEPTS' | 'NON_STANDARD_CONCEPTS' | 'STANDARD_OR_CODE_ID_MATCH';

export const StandardConceptFilter = {
    AllConcepts: 'ALL_CONCEPTS' as StandardConceptFilter,
    StandardConcepts: 'STANDARD_CONCEPTS' as StandardConceptFilter,
    NonStandardConcepts: 'NON_STANDARD_CONCEPTS' as StandardConceptFilter,
    StandardOrCodeIdMatch: 'STANDARD_OR_CODE_ID_MATCH' as StandardConceptFilter
};

