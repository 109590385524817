/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AchillesResult } from './achillesResult';
import { AchillesResultDist } from './achillesResultDist';


export interface Analysis { 
    /**
     * id analysis
     */
    analysisId: number;
    /**
     * analysis name
     */
    analysisName?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum1Name?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum2Name?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum3Name?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum4Name?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum5Name?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum6Name?: string;
    /**
     * usually concept name corresponding to stratum
     */
    stratum7Name?: string;
    /**
     * chart type to display for this analysis column pie box
     */
    chartType?: string;
    /**
     * data type of this analysis count or distribution
     */
    dataType?: string;
    /**
     * count results
     */
    results?: Array<AchillesResult>;
    /**
     * distribution results
     */
    distResults?: Array<AchillesResultDist>;
    /**
     * unit name
     */
    unitName?: string;
}

