/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AchillesResult,
  Analysis,
  AnalysisListResponse,
  CdrVersion,
  ConceptAnalysisListResponse,
  ConceptListResponse,
  CountAnalysis,
  CriteriaListResponse,
  CriteriaParentResponse,
  DomainInfosAndSurveyModulesResponse,
  SearchConceptsRequest,
  SurveyQuestionFetchResponse,
  SurveyVersionCountResponse,
} from '../models/index';
import {
    AchillesResultFromJSON,
    AchillesResultToJSON,
    AnalysisFromJSON,
    AnalysisToJSON,
    AnalysisListResponseFromJSON,
    AnalysisListResponseToJSON,
    CdrVersionFromJSON,
    CdrVersionToJSON,
    ConceptAnalysisListResponseFromJSON,
    ConceptAnalysisListResponseToJSON,
    ConceptListResponseFromJSON,
    ConceptListResponseToJSON,
    CountAnalysisFromJSON,
    CountAnalysisToJSON,
    CriteriaListResponseFromJSON,
    CriteriaListResponseToJSON,
    CriteriaParentResponseFromJSON,
    CriteriaParentResponseToJSON,
    DomainInfosAndSurveyModulesResponseFromJSON,
    DomainInfosAndSurveyModulesResponseToJSON,
    SearchConceptsRequestFromJSON,
    SearchConceptsRequestToJSON,
    SurveyQuestionFetchResponseFromJSON,
    SurveyQuestionFetchResponseToJSON,
    SurveyVersionCountResponseFromJSON,
    SurveyVersionCountResponseToJSON,
} from '../models/index';

export interface DataBrowserApiGetConceptAnalysisResultsRequest {
    conceptIds: Array<string>;
    domainId?: string;
}

export interface DataBrowserApiGetCountAnalysisRequest {
    domainId: string;
    domainDesc: string;
}

export interface DataBrowserApiGetCriteriaChildrenRequest {
    parentId: number;
}

export interface DataBrowserApiGetCriteriaRolledCountsRequest {
    conceptId: number;
    domain: string;
}

export interface DataBrowserApiGetDomainTotalsRequest {
    searchWord?: string;
    testFilter?: number;
    orderFilter?: number;
}

export interface DataBrowserApiGetFitbitAnalysisResultsRequest {
    conceptNames: Array<string>;
}

export interface DataBrowserApiGetSourceConceptsRequest {
    conceptId: number;
    minCount?: number;
}

export interface DataBrowserApiGetSubQuestionsRequest {
    surveyConceptId: number;
    questionConceptId: number;
    answerConceptId: number;
    level: number;
    path?: string;
}

export interface DataBrowserApiGetSurveyQuestionCountsRequest {
    questionConceptId: string;
    questionPath: string;
}

export interface DataBrowserApiGetSurveyQuestionResultsRequest {
    surveyConceptId: number;
    questionConceptId: number;
    questionPath?: string;
}

export interface DataBrowserApiGetSurveyQuestionsRequest {
    surveyConceptId: number;
    searchWord?: string;
}

export interface DataBrowserApiGetSurveyVersionCountsRequest {
    surveyConceptId: number;
}

export interface DataBrowserApiSearchConceptsOperationRequest {
    request?: SearchConceptsRequest;
}

/**
 * 
 */
export class DataBrowserApi extends runtime.BaseAPI {

    /**
     * Gets the cdr versions
     */
    async getCdrVersionUsedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CdrVersion>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/cdrversion-used`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CdrVersionFromJSON(jsonValue));
    }

    /**
     * Gets the cdr versions
     */
    async getCdrVersionUsed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CdrVersion> {
        const response = await this.getCdrVersionUsedRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets analysis results for concept
     */
    async getConceptAnalysisResultsRaw(requestParameters: DataBrowserApiGetConceptAnalysisResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptAnalysisListResponse>> {
        if (requestParameters['conceptIds'] == null) {
            throw new runtime.RequiredError(
                'conceptIds',
                'Required parameter "conceptIds" was null or undefined when calling getConceptAnalysisResults().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['conceptIds'] != null) {
            queryParameters['concept-ids'] = requestParameters['conceptIds']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['domainId'] != null) {
            queryParameters['domain-id'] = requestParameters['domainId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/concept-analysis-results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptAnalysisListResponseFromJSON(jsonValue));
    }

    /**
     * Gets analysis results for concept
     */
    async getConceptAnalysisResults(conceptIds: Array<string>, domainId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptAnalysisListResponse> {
        const response = await this.getConceptAnalysisResultsRaw({ conceptIds: conceptIds, domainId: domainId }, initOverrides);
        return await response.value();
    }

    /**
     * Gets EHR biological sex and age count analysis
     */
    async getCountAnalysisRaw(requestParameters: DataBrowserApiGetCountAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountAnalysis>> {
        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling getCountAnalysis().'
            );
        }

        if (requestParameters['domainDesc'] == null) {
            throw new runtime.RequiredError(
                'domainDesc',
                'Required parameter "domainDesc" was null or undefined when calling getCountAnalysis().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['domainId'] != null) {
            queryParameters['domain-id'] = requestParameters['domainId'];
        }

        if (requestParameters['domainDesc'] != null) {
            queryParameters['domain-desc'] = requestParameters['domainDesc'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/count-analysis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountAnalysisFromJSON(jsonValue));
    }

    /**
     * Gets EHR biological sex and age count analysis
     */
    async getCountAnalysis(domainId: string, domainDesc: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountAnalysis> {
        const response = await this.getCountAnalysisRaw({ domainId: domainId, domainDesc: domainDesc }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the children when parent concept is expanded in ui
     */
    async getCriteriaChildrenRaw(requestParameters: DataBrowserApiGetCriteriaChildrenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaListResponse>> {
        if (requestParameters['parentId'] == null) {
            throw new runtime.RequiredError(
                'parentId',
                'Required parameter "parentId" was null or undefined when calling getCriteriaChildren().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['parentId'] != null) {
            queryParameters['parentId'] = requestParameters['parentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/getCriteriaChildren`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaListResponseFromJSON(jsonValue));
    }

    /**
     * Gets the children when parent concept is expanded in ui
     */
    async getCriteriaChildren(parentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaListResponse> {
        const response = await this.getCriteriaChildrenRaw({ parentId: parentId }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the rolled up count from criteria
     */
    async getCriteriaRolledCountsRaw(requestParameters: DataBrowserApiGetCriteriaRolledCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CriteriaParentResponse>> {
        if (requestParameters['conceptId'] == null) {
            throw new runtime.RequiredError(
                'conceptId',
                'Required parameter "conceptId" was null or undefined when calling getCriteriaRolledCounts().'
            );
        }

        if (requestParameters['domain'] == null) {
            throw new runtime.RequiredError(
                'domain',
                'Required parameter "domain" was null or undefined when calling getCriteriaRolledCounts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['conceptId'] != null) {
            queryParameters['conceptId'] = requestParameters['conceptId'];
        }

        if (requestParameters['domain'] != null) {
            queryParameters['domain'] = requestParameters['domain'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/getCriteriaRolledCounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CriteriaParentResponseFromJSON(jsonValue));
    }

    /**
     * Gets the rolled up count from criteria
     */
    async getCriteriaRolledCounts(conceptId: number, domain: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CriteriaParentResponse> {
        const response = await this.getCriteriaRolledCountsRaw({ conceptId: conceptId, domain: domain }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the domain filters and survey modules with the count of all concepts and questions
     */
    async getDomainTotalsRaw(requestParameters: DataBrowserApiGetDomainTotalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainInfosAndSurveyModulesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['searchWord'] != null) {
            queryParameters['searchWord'] = requestParameters['searchWord'];
        }

        if (requestParameters['testFilter'] != null) {
            queryParameters['testFilter'] = requestParameters['testFilter'];
        }

        if (requestParameters['orderFilter'] != null) {
            queryParameters['orderFilter'] = requestParameters['orderFilter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/domain-totals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainInfosAndSurveyModulesResponseFromJSON(jsonValue));
    }

    /**
     * Gets the domain filters and survey modules with the count of all concepts and questions
     */
    async getDomainTotals(searchWord?: string, testFilter?: number, orderFilter?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainInfosAndSurveyModulesResponse> {
        const response = await this.getDomainTotalsRaw({ searchWord: searchWord, testFilter: testFilter, orderFilter: orderFilter }, initOverrides);
        return await response.value();
    }

    /**
     * Gets analysis results of fitbit
     */
    async getFitbitAnalysisResultsRaw(requestParameters: DataBrowserApiGetFitbitAnalysisResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptAnalysisListResponse>> {
        if (requestParameters['conceptNames'] == null) {
            throw new runtime.RequiredError(
                'conceptNames',
                'Required parameter "conceptNames" was null or undefined when calling getFitbitAnalysisResults().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['conceptNames'] != null) {
            queryParameters['concept-names'] = requestParameters['conceptNames']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/fitbit-analysis-results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptAnalysisListResponseFromJSON(jsonValue));
    }

    /**
     * Gets analysis results of fitbit
     */
    async getFitbitAnalysisResults(conceptNames: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptAnalysisListResponse> {
        const response = await this.getFitbitAnalysisResultsRaw({ conceptNames: conceptNames }, initOverrides);
        return await response.value();
    }

    /**
     * Gets results for an analysis id
     */
    async getGenderAnalysisRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Analysis>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/gender-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisFromJSON(jsonValue));
    }

    /**
     * Gets results for an analysis id
     */
    async getGenderAnalysis(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Analysis> {
        const response = await this.getGenderAnalysisRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets results for an analysis id and stratum
     */
    async getParticipantCountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AchillesResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/participant-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AchillesResultFromJSON(jsonValue));
    }

    /**
     * Gets results for an analysis id and stratum
     */
    async getParticipantCount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AchillesResult> {
        const response = await this.getParticipantCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get children of the given concept
     */
    async getSourceConceptsRaw(requestParameters: DataBrowserApiGetSourceConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptListResponse>> {
        if (requestParameters['conceptId'] == null) {
            throw new runtime.RequiredError(
                'conceptId',
                'Required parameter "conceptId" was null or undefined when calling getSourceConcepts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['conceptId'] != null) {
            queryParameters['concept_id'] = requestParameters['conceptId'];
        }

        if (requestParameters['minCount'] != null) {
            queryParameters['minCount'] = requestParameters['minCount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/source-concepts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptListResponseFromJSON(jsonValue));
    }

    /**
     * Get children of the given concept
     */
    async getSourceConcepts(conceptId: number, minCount?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptListResponse> {
        const response = await this.getSourceConceptsRaw({ conceptId: conceptId, minCount: minCount }, initOverrides);
        return await response.value();
    }

    /**
     * Get survey sub questions
     */
    async getSubQuestionsRaw(requestParameters: DataBrowserApiGetSubQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SurveyQuestionFetchResponse>> {
        if (requestParameters['surveyConceptId'] == null) {
            throw new runtime.RequiredError(
                'surveyConceptId',
                'Required parameter "surveyConceptId" was null or undefined when calling getSubQuestions().'
            );
        }

        if (requestParameters['questionConceptId'] == null) {
            throw new runtime.RequiredError(
                'questionConceptId',
                'Required parameter "questionConceptId" was null or undefined when calling getSubQuestions().'
            );
        }

        if (requestParameters['answerConceptId'] == null) {
            throw new runtime.RequiredError(
                'answerConceptId',
                'Required parameter "answerConceptId" was null or undefined when calling getSubQuestions().'
            );
        }

        if (requestParameters['level'] == null) {
            throw new runtime.RequiredError(
                'level',
                'Required parameter "level" was null or undefined when calling getSubQuestions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['surveyConceptId'] != null) {
            queryParameters['survey_concept_id'] = requestParameters['surveyConceptId'];
        }

        if (requestParameters['questionConceptId'] != null) {
            queryParameters['question_concept_id'] = requestParameters['questionConceptId'];
        }

        if (requestParameters['answerConceptId'] != null) {
            queryParameters['answer_concept_id'] = requestParameters['answerConceptId'];
        }

        if (requestParameters['level'] != null) {
            queryParameters['level'] = requestParameters['level'];
        }

        if (requestParameters['path'] != null) {
            queryParameters['path'] = requestParameters['path'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/sub-questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionFetchResponseFromJSON(jsonValue));
    }

    /**
     * Get survey sub questions
     */
    async getSubQuestions(surveyConceptId: number, questionConceptId: number, answerConceptId: number, level: number, path?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SurveyQuestionFetchResponse> {
        const response = await this.getSubQuestionsRaw({ surveyConceptId: surveyConceptId, questionConceptId: questionConceptId, answerConceptId: answerConceptId, level: level, path: path }, initOverrides);
        return await response.value();
    }

    /**
     * Gets participant count of question by each stratum
     */
    async getSurveyQuestionCountsRaw(requestParameters: DataBrowserApiGetSurveyQuestionCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalysisListResponse>> {
        if (requestParameters['questionConceptId'] == null) {
            throw new runtime.RequiredError(
                'questionConceptId',
                'Required parameter "questionConceptId" was null or undefined when calling getSurveyQuestionCounts().'
            );
        }

        if (requestParameters['questionPath'] == null) {
            throw new runtime.RequiredError(
                'questionPath',
                'Required parameter "questionPath" was null or undefined when calling getSurveyQuestionCounts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['questionConceptId'] != null) {
            queryParameters['questionConceptId'] = requestParameters['questionConceptId'];
        }

        if (requestParameters['questionPath'] != null) {
            queryParameters['questionPath'] = requestParameters['questionPath'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/survey-question-counts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisListResponseFromJSON(jsonValue));
    }

    /**
     * Gets participant count of question by each stratum
     */
    async getSurveyQuestionCounts(questionConceptId: string, questionPath: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalysisListResponse> {
        const response = await this.getSurveyQuestionCountsRaw({ questionConceptId: questionConceptId, questionPath: questionPath }, initOverrides);
        return await response.value();
    }

    /**
     * Get Survey Question Results
     */
    async getSurveyQuestionResultsRaw(requestParameters: DataBrowserApiGetSurveyQuestionResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalysisListResponse>> {
        if (requestParameters['surveyConceptId'] == null) {
            throw new runtime.RequiredError(
                'surveyConceptId',
                'Required parameter "surveyConceptId" was null or undefined when calling getSurveyQuestionResults().'
            );
        }

        if (requestParameters['questionConceptId'] == null) {
            throw new runtime.RequiredError(
                'questionConceptId',
                'Required parameter "questionConceptId" was null or undefined when calling getSurveyQuestionResults().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['surveyConceptId'] != null) {
            queryParameters['survey_concept_id'] = requestParameters['surveyConceptId'];
        }

        if (requestParameters['questionConceptId'] != null) {
            queryParameters['question_concept_id'] = requestParameters['questionConceptId'];
        }

        if (requestParameters['questionPath'] != null) {
            queryParameters['questionPath'] = requestParameters['questionPath'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/survey-question-results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisListResponseFromJSON(jsonValue));
    }

    /**
     * Get Survey Question Results
     */
    async getSurveyQuestionResults(surveyConceptId: number, questionConceptId: number, questionPath?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalysisListResponse> {
        const response = await this.getSurveyQuestionResultsRaw({ surveyConceptId: surveyConceptId, questionConceptId: questionConceptId, questionPath: questionPath }, initOverrides);
        return await response.value();
    }

    /**
     * Get survey questions
     */
    async getSurveyQuestionsRaw(requestParameters: DataBrowserApiGetSurveyQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SurveyQuestionFetchResponse>> {
        if (requestParameters['surveyConceptId'] == null) {
            throw new runtime.RequiredError(
                'surveyConceptId',
                'Required parameter "surveyConceptId" was null or undefined when calling getSurveyQuestions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['surveyConceptId'] != null) {
            queryParameters['survey_concept_id'] = requestParameters['surveyConceptId'];
        }

        if (requestParameters['searchWord'] != null) {
            queryParameters['search_word'] = requestParameters['searchWord'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/survey-questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyQuestionFetchResponseFromJSON(jsonValue));
    }

    /**
     * Get survey questions
     */
    async getSurveyQuestions(surveyConceptId: number, searchWord?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SurveyQuestionFetchResponse> {
        const response = await this.getSurveyQuestionsRaw({ surveyConceptId: surveyConceptId, searchWord: searchWord }, initOverrides);
        return await response.value();
    }

    /**
     * Get Survey Version Counts
     */
    async getSurveyVersionCountsRaw(requestParameters: DataBrowserApiGetSurveyVersionCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SurveyVersionCountResponse>> {
        if (requestParameters['surveyConceptId'] == null) {
            throw new runtime.RequiredError(
                'surveyConceptId',
                'Required parameter "surveyConceptId" was null or undefined when calling getSurveyVersionCounts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['surveyConceptId'] != null) {
            queryParameters['survey_concept_id'] = requestParameters['surveyConceptId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/survey-version-counts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyVersionCountResponseFromJSON(jsonValue));
    }

    /**
     * Get Survey Version Counts
     */
    async getSurveyVersionCounts(surveyConceptId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SurveyVersionCountResponse> {
        const response = await this.getSurveyVersionCountsRaw({ surveyConceptId: surveyConceptId }, initOverrides);
        return await response.value();
    }

    /**
     * Gets list of matched concepts
     */
    async searchConceptsRaw(requestParameters: DataBrowserApiSearchConceptsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aou_oauth", []);
        }

        const response = await this.request({
            path: `/v1/databrowser/searchConcepts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchConceptsRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptListResponseFromJSON(jsonValue));
    }

    /**
     * Gets list of matched concepts
     */
    async searchConcepts(request?: SearchConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptListResponse> {
        const response = await this.searchConceptsRaw({ request: request }, initOverrides);
        return await response.value();
    }

}
