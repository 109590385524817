/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * a domain for concepts corresponding to a table in the OMOP schema
 * @export
 */
export const Domain = {
    OBSERVATION: 'OBSERVATION',
    PROCEDURE: 'PROCEDURE',
    DRUG: 'DRUG',
    CONDITION: 'CONDITION',
    MEASUREMENT: 'MEASUREMENT',
    DEVICE: 'DEVICE',
    DEATH: 'DEATH',
    VISIT: 'VISIT'
} as const;
export type Domain = typeof Domain[keyof typeof Domain];


export function instanceOfDomain(value: any): boolean {
    for (const key in Domain) {
        if (Object.prototype.hasOwnProperty.call(Domain, key)) {
            if (Domain[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DomainFromJSON(json: any): Domain {
    return DomainFromJSONTyped(json, false);
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
    return json as Domain;
}

export function DomainToJSON(value?: Domain | null): any {
    return value as any;
}

